import { TextField } from "@mui/material";
import React from "react";
import { FiSearch } from "react-icons/fi";
import { MdClose } from "react-icons/md";

const NombreOnlyFiltro = ({ values, setValues }) => {
  const [nombre, setNombre] = React.useState("");

  React.useEffect(() => {
    setValues({
      ...values,
      nombreOnly: {
        value: nombre,
        filter: (row) =>
          row.nombre.toLowerCase().includes(nombre.toLowerCase()),
      },
    });
  }, [nombre]);

  React.useEffect(() => {
    if (values.reset) {
      setNombre("");
    }
  }, [values.reset]);

  return (
    <TextField
      fullWidth
      id="outlined-basic"
      label="Buscar por nombre"
      variant="standard"
      name="nombre"
      value={nombre}
      onChange={(e) => setNombre(e.target.value)}
      InputProps={{
        startAdornment: <FiSearch style={{ marginRight: "10px" }} />,
        endAdornment: (
          <MdClose
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => setNombre("")}
          />
        ),
      }}
    />
  );
};

export default NombreOnlyFiltro;

import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const S3Pacientes = () => {
  const [values, setValues] = React.useState({
    nombre: "",
    fechaInicio: null,
    fechaFin: null,
    cuenta: "",
    key: "",
  });
  const [results, setResults] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSearch = () => {
    setFetching(true);
    setError(null);
    fetch(
      `${process.env.REACT_APP_URL_BACK}/api/v1/s3operations/bd437d73da0743a10ebdb4648579ac9b`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setResults(data);
        setFetching(false);
      })
      .catch((err) => {
        console.error(err);
        setFetching(false);
        setError(err);
      });
  };

  React.useEffect(() => {
    const rol = JSON.parse(localStorage.getItem("userData") || {}).user?.rol;
    if (!rol.includes("SOPORTE")) {
      window.location.href = "/dashboard";
    }
  }, []);

  return (
    <Grid container spacing={2} p={2} alignItems={"start"}>
      <Grid item container xs={6} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Filtros</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Nombre del paciente</Typography>
          <TextField
            sx={{ mt: 1 }}
            variant="filled"
            value={values.nombre}
            onChange={(e) => handleChange("nombre")(e)}
            fullWidth
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ mb: 3 }}>
            Rango de fechas
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label="Fecha inicio"
              inputFormat="DD/MM/YYYY"
              value={values?.fechaInicio}
              onChange={(date) => setValues({ ...values, fechaInicio: date })}
              renderInput={(params) => (
                <TextField variant="filled" {...params} />
              )}
            />
            <DesktopDatePicker
              label="Fecha fin"
              inputFormat="DD/MM/YYYY"
              value={values?.fechaFin}
              onChange={(date) => setValues({ ...values, fechaFin: date })}
              renderInput={(params) => (
                <TextField variant="filled" {...params} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Numero de Cuenta</Typography>
          <TextField
            sx={{ mt: 1 }}
            variant="filled"
            value={values.cuenta}
            onChange={(e) => handleChange("cuenta")(e)}
            fullWidth
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Key</Typography>
          <TextField
            sx={{ mt: 1 }}
            variant="filled"
            value={values?.key}
            onChange={(e) => handleChange("key")(e)}
            fullWidth
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => handleSearch()}
            variant="contained"
            fullWidth
            color="primary"
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
      <Grid item container xs={6}>
        <Grid item xs={12}>
          <Typography variant="h4" component={"p"}>
            Resultados
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer sx={{ maxHeight: "90vh", overflowY: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id de Archivo</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Descargar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!fetching &&
                  !error &&
                  results.map((result, index) => (
                    <TableRow key={"s" + index}>
                      <TableCell>{result.id}</TableCell>
                      <TableCell>
                        {new Date(result.fecha).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          href={result.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Descargar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {!fetching && !error && results.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography variant="h4" textAlign={"center"}>
                        No se encontraron resultados
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {!fetching && error && (
                  <>
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography variant="h4" textAlign={"center"}>
                          Ocurrio un error
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                        <img
                          src="https://ih1.redbubble.net/image.1056685820.9299/raf,360x360,075,t,fafafa:ca443f4786.u1.jpg"
                          alt={"error"}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {fetching && (
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems={"center"}
          >
            <Grid item xs={12} textAlign={"center"}>
              <img
                src="https://media3.giphy.com/media/JIX9t2j0ZTN9S/200w.gif?cid=6c09b952pcdqrk6t6z731d3xkcorn9o1227cga3si7j9061k&ep=v1_gifs_search&rid=200w.gif&ct=g"
                alt="loading"
                width="400"
                height="400"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" textAlign={"center"}>
                Cargando...
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default S3Pacientes;

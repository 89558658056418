import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import verifyToken from "../services/verifyToken";

const LoaderToken = () => {
  const [open, setOpen] = React.useState(true);

  const checkAuth = async () => {
    await verifyToken();
    setOpen(false);
  };

  React.useEffect(() => {
    checkAuth();
  }, []);

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 9999999999999,
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoaderToken;

export default function millisToMinutesAndSecondsShort(s) {
  // Pad to 2 or 3 digits, default is 2
  function pad(n, z) {
    z = z || 2;
    return ("00" + n).slice(-z);
  }

  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;

  return `${hrs > 0 ? pad(hrs) + ":" : "00:"}${
    mins > 0 ? pad(mins) + ":" : "00:"
  }${secs > 0 ? pad(secs) + "" : "00"}`;
}

import * as React from "react";
import { Box, Grid, IconButton, styled } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { BiX } from "react-icons/bi";

const url = process.env.REACT_APP_URL_BACK;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: (theme) => theme.palette.secondary.main,
        color: (theme) => theme.palette.secondary.contrastText,
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <BiX />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function RecuperaContrasenaDialog({ open, setOpen }) {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);

  const handleClose = () => {
    setOpen(false);
    setEmail("");
    setError(null);
    setSuccess(null);
  };

  const sendEmail = () => {
    setError(null);
    setSuccess(null);
    const validEmail = email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (!validEmail) {
      setError("Por favor ingrese un email válido");
      return;
    }
    fetch(url+"/api/v1/usuarios/forgotPassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: email.trim(),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // reset values
        if (data.error) {
          setError(data.error.message);
        } else {
          setSuccess(data.message);
        }
      })
      .catch((errorCatch) => {
        console.log("error", errorCatch);
        setError(errorCatch?.message);
      });
  };
  return (
    <BootstrapDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Recuperar contraseña
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Grid
          item
          container
          justifyContent={"space-between"}
          xs={12}
          sx={{ flexGrow: 1 }}
        >
          <TextField
            label="Correo electrónico"
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value.trim());
            }}
            variant="outlined"
            sx={{
              width: "1000px",
            }}
            margin="normal"
            required
          />
        </Grid>
        {error ? (
          <Grid item xs={12}>
            <Box
              sx={{
                color: "red",
                fontSize: "14px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Error: {error}
            </Box>
          </Grid>
        ) : null}
        {success ? (
          <Grid item xs={12}>
            <Box
              sx={{
                color: "green",
                fontSize: "14px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {success}
            </Box>
          </Grid>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={sendEmail} color="primary">
          Enviar
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

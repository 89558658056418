// Ant Chart Design
import { Column, Gauge, Pie, DualAxes } from "@ant-design/plots";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

// Mui
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// Filtro
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
// Calendario
import TextField from "@mui/material/TextField";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import iconoPaciente from "../assets/iconoPaciente.png";
import iconoFiltro from "../assets/iconofiltro.gif";
import iconoViñeta from "../assets/vineta.gif";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Form, Formik } from "formik";
import socketIOClient from "socket.io-client";
import * as Yup from "yup";
import makeRequest from "../services/makeRequest";
import verifyToken from "../services/verifyToken";

import {
  ComposedChart,
  Line,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Scatter,
  Bar,
  ResponsiveContainer,
} from "recharts";

const url = process.env.REACT_APP_URL_BACK;

const DisplayingErrorMessagesSchema = Yup.object().shape({
  startDate: Yup.date()
    .nullable()
    .typeError("La fecha inicial no puede ser la actual")
    .required("La fecha inicial es obligatoria"),

  endDate: Yup.date()
    .nullable()
    .min(Yup.ref("startDate"), ({ min }) => "La fecha no puede ser menor")
    .required("La fecha final es obligatoria")
    .typeError("La fecha final no puede ser la actual"),
});
const PacienteGraficas = ({ userData }) => {
  const [data, setData] = React.useState({});
  const [aseguradoras, setAseguradoras] = React.useState([]);
  const [values, setValues] = React.useState({
    aseguradora: null,
    tipo: 0,
    startDate: new Date().setUTCHours(0, 0, 0, 0),
    endDate: new Date().setUTCHours(23, 59, 59, 999),
  });
  const [fecha, setFecha] = React.useState(null);
  const [piso, setPiso] = React.useState(0);
  const [filterUsed, setFilterUsed] = React.useState(false);
  const [fetching, setFetching] = React.useState(true);

  // gráfica

  const paletteSemanticRed = "#42b029";
  const brandColor = "#5B8FF9";

  const config = {
    data: data?.pacientesPorEtapa || [],
    xField: "actividad",
    yField: "value",
    seriesField: "",
    color: ({ type }) => {
      if (type === "Completo" || type === "Completo") {
        return paletteSemanticRed;
      }

      return brandColor;
    },
    label: {
      content: (originData) => {
        const val = parseFloat(originData.value);

        if (val < 0.05) {
          return (val * 100).toFixed(1);
        }
      },
      offset: 10,
    },
    cursor: "pointer",
    legend: false,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: true,
      },
    },
    interactions: [{ type: "element-highlight" }],
    onReady: ({ chart }) => {
      console.log(chart);
      chart.on("interval:click", (evento) => {
        const id = evento.data.data.id;
        //redirect
        window.location.href = `/catalogoPaciente/${id}`;
      });
    },
  };

  //Gráfica 2
  const config2 = {
    data: data.altasMedicos || [],
    xField: "number",
    yField: "nombre",
    seriesField: "type",
    color: ({ type }) => {
      return "#5B8FF9";
    },
    legend: false,
  };

  // Grafica Ocupación
  const ocupacion = {
    height: 400,
    appendPadding: 10,
    data: data?.ocupacionPacientes || [],
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-50%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  const paletteSemanticRed2 = "#585858";
  const brandColor2 = "#9e9a9a";

  const tiempos = {
    data: data?.tiemposPorEtapa || [],
    isGroup: true,
    xField: "name",
    yField: "value",

    /** 自定义颜色 */
    // color: ['#1383ab', '#c52125'],
    seriesField: "type",
    marginRatio: 0,
    label: {
      // 可手动配置 label 数据标签位置
      position: "middle",
      // 'left', 'middle', 'right'
      // 可配置附加的布局方法
      layout: [
        // 柱形图数据标签位置自动调整
        {
          type: "interval-adjust-position",
        }, // 数据标签防遮挡
        {
          type: "interval-hide-overlap",
        }, // 数据标签文颜色自动调整
        {
          type: "adjust-color",
        },
      ],
    },
  };

  React.useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
      transports: ["websocket"],
    });
    socket.emit("dashboard", piso);
    socket.on("updateDashboard", (data) => {
      setData(data);
      setFetching(false);
    });

    socket.on("actividadRealizada", () => {
      console.log("actividadRealizada");
      console.log("PISO", piso);
      socket.emit("dashboard", piso);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    verifyToken();
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url + `/api/v1/pacientes/form`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const aseguradorasAux = data["aseguradoras"].sort((a, b) =>
          a.acronimo > b.acronimo ? 1 : -1
        );
        setAseguradoras(aseguradorasAux || []);
      });
  }, []);

  const fetchData = async () => {
    setFilterUsed(true);
    setPiso(0);
    await makeRequest("/api/v1/dashboard/estadisticas", "POST", {
      startDate: values.startDate,
      endDate: values.endDate,
      tipo: values.tipo,
      aseguradora: values.aseguradora ? values.aseguradora._id : undefined,
    }).then((result) => {
      setData({ ...data, ...result });
    });
  };

  const limpiarFiltro = async () => {
    setValues({
      aseguradora: null,
      tipo: 0,
      startDate: new Date().setUTCHours(0, 0, 0, 0),
      endDate: new Date().setUTCHours(23, 59, 59, 999),
    });
    await makeRequest("/api/v1/dashboard/limpiarFiltro", "GET").then(
      (result) => {
        console.log(result);
      }
    );
    setFilterUsed(false);
  };
  if (fetching)
    return (
      <Grid
        container
        height={"100vh"}
        justifyContent={"center"}
        alignContent={"center"}
      >
        <Typography variant={"h3"} width={"100%"} textAlign={"center"} mb={2}>
          Obteniendo información...
        </Typography>
        <CircularProgress />
      </Grid>
    );
  return (
    <Grid
      className="containerGraficas"
      // container
      // direction={"column"}
      // alignItems={"center"}
      // justifyContent={"center"}
      // spacing={3}
      container
      direction={"row"}
      justifyContent="center"
      alignItems={"center"}
    >
      <Grid md={7}>
        <div className="cardPuerta" style={{ padding: "10px 25px" }}>
          <p className="tituloPaciente">
            <img className="viñeta2" src={iconoPaciente} />{" "}
            {userData.user.nombre}
          </p>
        </div>
      </Grid>
      <Grid md={5}>
        <div className="" style={{ padding: "15px " }}>
          <Accordion className="plecaFliltro">
            <AccordionSummary
              className="plecaFliltro"
              expandIcon={<MdOutlineArrowDropDownCircle />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="tituloFiltro">
                <img className="iconoFiltro" src={iconoFiltro} />
                Filtro
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                zIndex: 1,
                position: "absolute",
                width: "100%",
                background: (theme) => theme.palette.primary.main,
                borderRadius: "10px",
                boxShadow: "0px 8px 10px 0px rgba(0,0,0,0.30)",
              }}
            >
              <div className="listFiltro">
                <FormControl fullWidth>
                  <InputLabel id="pacientetype">Tipo de pacientes</InputLabel>
                  <Select
                    value={values.tipo}
                    labelId="pacientetype"
                    label="Tipo de pacientes"
                    fullWidth
                    onChange={(event) => {
                      setValues({ ...values, tipo: event.target.value });
                    }}
                  >
                    <MenuItem value={0}>Todos</MenuItem>
                    <MenuItem value={1}>Asegurados</MenuItem>
                    <MenuItem value={2}>Particulares</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="listFiltro">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Formik
                    initialValues={{
                      startDate: { fecha },
                      endDate: { fecha },
                    }}
                    validationSchema={DisplayingErrorMessagesSchema}
                  >
                    {(props) => (
                      <Form>
                        <Grid container sx={12}>
                          <Grid item xs={5}>
                            <DesktopDatePicker
                              fullWidth
                              disableFuture
                              label="Fecha Inicio"
                              inputFormat="DD/MM/YYYY"
                              name="startDate"
                              value={values.startDate}
                              onChange={(value) => {
                                props.setFieldValue("startDate", value);
                                setValues({ ...values, startDate: value._d });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  color="secondary"
                                  sx={{ bgcolor: "white" }}
                                  {...params}
                                  size="small"
                                  variant="filled"
                                />
                              )}
                            />
                            {props.values.startDate &&
                              props.errors.startDate && (
                                <div>{props.errors.startDate}</div>
                              )}
                          </Grid>
                          <Grid item xs={1} alignItems="center">
                            <Typography
                              variant={"h3"}
                              textAlign={"center"}
                            >{` - `}</Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <DesktopDatePicker
                              disableFuture
                              label="Fecha Final"
                              inputFormat="DD/MM/YYYY"
                              name="endDate"
                              value={values.endDate}
                              onChange={(value) => {
                                props.setFieldValue("endDate", value);
                                setValues({ ...values, endDate: value._d });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  color="secondary"
                                  sx={{ bgcolor: "white" }}
                                  {...params}
                                  size="small"
                                  variant="filled"
                                />
                              )}
                            />

                            {props.values.endDate && props.errors.endDate && (
                              <div>{props.errors.endDate}</div>
                            )}
                          </Grid>
                          <Grid item xs={1}></Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </LocalizationProvider>
              </div>
              <div className="listFiltro">
                <FormControl fullWidth>
                  <Autocomplete
                    value={values.aseguradora}
                    disablePortal
                    fullWidth
                    id="combo-box-demo"
                    options={aseguradoras}
                    disabled={values.tipo === 2 || values.tipo === 0}
                    onChange={(_event, newValue) => {
                      setValues({ ...values, aseguradora: newValue });
                    }}
                    getOptionLabel={(option) => {
                      return `${option.acronimo}`;
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        placeholder="Sin Aseguradora"
                        label="Aseguradora"
                        {...params}
                      />
                    )}
                  />
                </FormControl>
              </div>

              <Grid item xs={6}>
                <Button
                  className="botonFiltro"
                  variant="contained"
                  color="secondary"
                  type="submit"
                  sx={{ mt: 2 }}
                  onClick={() => {
                    limpiarFiltro();
                  }}
                >
                  Limpiar Filtros
                </Button>
                <Button
                  className="botonFiltro"
                  variant="contained"
                  color="secondary"
                  type="submit"
                  sx={{ mt: 2 }}
                  onClick={() => {
                    fetchData();
                  }}
                >
                  Filtrar Resultados
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      </Grid>
      <Grid
        md={12}
        sx={{
          "& canvas": {
            cursor: "pointer !important",
          },
        }}
      >
        <div className="cardPuerta">
          <p className="tituloCard">
            <div>
              {filterUsed ? (
                <div className="viñetaEstado"></div>
              ) : (
                <img className="viñeta" src={iconoViñeta} />
              )}
              Paciente por etapa
            </div>
          </p>
          <Column {...config} style={{ cursor: "pointer" }} />
        </div>
      </Grid>

      <Grid md={12}>
        <div className="cardPuerta ">
          <div className="h-800-scroll">
            <p className="tituloCard">
              <div>
                {filterUsed ? (
                  <div className="viñetaEstado"></div>
                ) : (
                  <img className="viñeta" src={iconoViñeta} />
                )}
                Cumplimiento SLA´s
              </div>
            </p>
            {data?.cumplimientosSLAS?.map((item, index) => {
              let color = "#42b029";
              if (item.value < 0.8 && item.value > 0.5) {
                color = "#FAAD14";
              } else if (item.value < 0.9) {
                color = "#e81d70";
              }

              const configGraph = {
                percent: item.value,
                innerRadius: 0.75,
                height: 150,
                range: {
                  color: color,
                },
                startAngle: Math.PI,
                endAngle: 3 * Math.PI,
                indicator: null,
                statistic: {
                  title: {
                    offsetY: -16,
                    style: {
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "#4B535E",
                    },
                    formatter: () => `${(item.value * 100).toFixed(0)}%`,
                  },
                  content: {
                    style: {
                      fontSize: "12px",
                      fontWeight: "bold",
                      lineHeight: "50px",
                      color: "#4B535E",
                    },
                    formatter: () => item.actividad,
                  },
                },
              };
              return (
                <div key={"slas" + index} className="colPuerta">
                  <Gauge {...configGraph} />
                </div>
              );
            })}
          </div>
        </div>
      </Grid>

      <Grid md={7}>
        <div className="cardPuerta">
          <div className="h-500-scroll">
            <p className="tituloCard">
              <div>
                {filterUsed ? (
                  <div className="viñetaEstado"></div>
                ) : (
                  <img className="viñeta" src={iconoViñeta} />
                )}
                Pacientes eliminados y tiempo excedente
              </div>
            </p>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="tablaPacienesEliminados"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell align="center">Etapa</TableCell>
                    <TableCell align="center">Tipo</TableCell>
                    <TableCell align="center">Tiempo total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.tablero?.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.etapa}</TableCell>
                      <TableCell align="center">{row.type}</TableCell>
                      <TableCell align="center">
                        {row.type === "Eliminado" ? "~" : row.tiempo} min
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Grid>

      <Grid md={5}>
        <div className="cardPuerta">
          <div className="">
            <p className="tituloCard">
              <div>
                {filterUsed ? (
                  <div className="viñetaEstado"></div>
                ) : (
                  <img className="viñeta" src={iconoViñeta} />
                )}
                Ocupación Diaria
              </div>
            </p>
            <Pie {...ocupacion} />
          </div>
        </div>
      </Grid>

      <Grid md={12}>
        <div className="cardPuerta">
          <p className="tituloCard">
            <div>
              {filterUsed ? (
                <div className="viñetaEstado"></div>
              ) : (
                <img className="viñeta" src={iconoViñeta} />
              )}
              Tiempos por etapa
            </div>
          </p>
          <div class="filtroGrafica">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Piso</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={piso}
                label="Piso"
                disabled={filterUsed}
                onChange={(event) => {
                  setPiso(event.target.value);
                  makeRequest(
                    "/api/v1/dashboard/tiemposPorPiso?piso=" +
                      event.target.value,
                    "GET"
                  ).then((result) => {
                    setData({ ...data, tiemposPorEtapa: result });
                  });
                }}
              >
                <MenuItem value={0}>Todos</MenuItem>
                <MenuItem value={1}>Piso 1</MenuItem>
                <MenuItem value={2}>Piso 2</MenuItem>
                <MenuItem value={3}>Piso 3</MenuItem>
                <MenuItem value={"S1"}>Sotano</MenuItem>
              </Select>
            </FormControl>
          </div>
          <ResponsiveContainer width="100%" height={600}>
            <ComposedChart
              width={1000}
              height={800}
              data={data.tiemposPorEtapa || []}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" />
              <YAxis
                type="number"
                allowDataOverflow
                dataKey={"Promedio"}
                orientation="left"
                yAxisId={"left"}
              />
              <YAxis
                type="number"
                dataKey={"Desviacion estandar"}
                allowDataOverflow
                orientation="right"
                yAxisId={"right"}
              />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="Promedio"
                unit=" min"
                fill="#5B92F5"
                yAxisId={"left"}
              />
              <Line
                dataKey="Desviacion estandar"
                stroke="#ff7300"
                yAxisId={"right"}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </Grid>
    </Grid>
  );
};

export default PacienteGraficas;

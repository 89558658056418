import {
  Backdrop,
  CircularProgress,
  Grid,
  InputAdornment,
  styled,
  Switch,
  Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import React from "react";
import Regex from "../../constants/regex";
import verifyToken from "../../services/verifyToken";
import { BootstrapDialog, BootstrapDialogTitle } from "../BootstrapDialog";
import LabelDialogForm from "../LabelDialogForm";
import ResponseError from "../ResponseError";

const url = process.env.REACT_APP_URL_BACK;

const EditarLimpiezaDialog = ({ open, setOpen, setRefresh, id }) => {
  const [values, setValues] = React.useState({
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    telefono: "",
    correo: "",
    estado: false,
  });

  const [errors, setErrors] = React.useState({});

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    setLoading(true);
    verifyToken();
    event.preventDefault();
    //validate values
    const validationErrors = validateFields(values);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length !== 0) return setLoading(false);
    values.cama = Number(values.cama);
    setError(null);
    //send request
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+"/api/v1/limpieza/actualizar", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
      body: JSON.stringify({
        _id: id,
        ...values,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // reset values
        if (data.error) {
          setError(data.error);
        } else {
          setValues({
            nombre: "",
            apellidoPaterno: "",
            apellidoMaterno: "",
            telefono: "",
            correo: "",
            estado: false,
          });

          setRefresh(true);
          setOpen(false);
          setLoading(false);
        }
      })
      .catch((_error) => {
        console.log("error", _error);
        setError(_error);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    verifyToken();
    if (id) {
      setLoading(true);
      verifyToken();
      const userData = JSON.parse(localStorage.getItem("userData")) || {};
      fetch(url+`/api/v1/limpieza/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (userData?.user?.token || ""),
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setValues({
            nombre: data.nombre || "",
            apellidoPaterno: data.apellidoPaterno || "",
            apellidoMaterno: data.apellidoMaterno || "",
            telefono: data.telefono || "",
            estado: data.estado || false,
            correo: data.correo || "",
          });
          setLoading(false);
        })
        .catch((_error) => {
          console.log("error", _error);
          setError(_error);
          setLoading(false);
          setOpen(false);
        });
    }
  }, [id, open]);

  return (
    <Grid>
      <Backdrop
        open={loading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 99999999,
        }}
      >
        <CircularProgress />
      </Backdrop>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Editar limpieza
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid
            item
            container
            justifyContent={"space-between"}
            xs={12}
            sx={{ flexGrow: 1 }}
          >
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Nombre"} />
              <TextField
                autoComplete="off"
                value={values.nombre}
                error={errors.nombre ? true : false}
                helperText={errors.nombre}
                name="nombre"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Apellido Paterno"} />
              <TextField
                autoComplete="off"
                value={values.apellidoPaterno}
                error={errors.apellidoPaterno ? true : false}
                helperText={errors.apellidoPaterno}
                name="apellidoPaterno"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Apellido Materno"} />
              <TextField
                autoComplete="off"
                value={values.apellidoMaterno}
                error={errors.apellidoMaterno ? true : false}
                helperText={errors.apellidoMaterno}
                name="apellidoMaterno"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Teléfono"} />
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{"+52"}</InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 10 }}
                autoComplete="off"
                value={values.telefono}
                error={errors.telefono ? true : false}
                helperText={errors.telefono}
                variant="outlined"
                name="telefono"
                fullWidth
                onChange={handleChange}
                type="text"
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Email"} />
              <TextField
                autoComplete="off"
                inputProps={{ maxLength: 30 }}
                value={values.correo}
                error={errors.correo ? true : false}
                helperText={errors.correo}
                name="correo"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item container xs={12} sx={{ m: 3 }}>
              <Grid item xs={6}>
                <Typography variant="h6">Estado</Typography>
              </Grid>
              <Grid
                container
                item
                xs={6}
                direction={"row"}
                gap={2}
                alignContent="center"
                justifyContent={"center"}
              >
                <Typography variant="h6">Inactivo</Typography>
                <IOSSwitch
                  checked={values.estado}
                  name="estado"
                  onChange={(e) =>
                    setValues({ ...values, estado: e.target.checked })
                  }
                />
                <Typography variant="h6">Activo</Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ResponseError error={error} />
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="secondary">
            Guardar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Grid>
  );
};

function validateFields(values) {
  let errors = {};

  Object.keys(values).forEach((key) => {
    if (!values[key]) {
      if (key === "estado") return;
      errors[key] = "Campo requerido";
    }
  });

  // check if nombre is all letters
  if (values.nombre && !Regex.nombres_personas.test(values.nombre)) {
    errors.nombre = "El nombre solo puede contener letras";
  }
  if (
    values.apellidoPaterno &&
    !Regex.nombres_personas.test(values.apellidoPaterno)
  ) {
    errors.apellidoPaterno = "El apellido paterno solo puede contener letras";
  }
  // check if nombre is all letters
  if (
    values.apellidoMaterno &&
    !Regex.nombres_personas.test(values.apellidoMaterno)
  ) {
    errors.apellidoMaterno = "El apellido materno solo puede contener letras";
  }
  if (values.telefono && !Regex.telefono.test(values.telefono)) {
    errors.telefono = "El telefono debe contener 10 digitos";
  }
  return errors;
}

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
export default EditarLimpiezaDialog;

import {
  Box,
  Button,
  DialogContent,
  Grid,
  MenuItem,
  Select
} from "@mui/material";
import React from "react";
import { RiSurveyLine } from "react-icons/ri";
import { BootstrapDialog, BootstrapDialogTitle } from "../BootstrapDialog";

import makeRequest from "../../services/makeRequest";
import CardCenso from "./CardCenso";

const ResumenCenso = ({ refresh }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [piso, setPiso] = React.useState(0);
  const [pacientes, setPacientes] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  async function fetchData() {
    const result = await makeRequest(
      `/api/v1/pacientes/resumenCenso?piso=${piso}`,
      "GET"
    );

    setPacientes(result);
  }

  React.useEffect(() => {
    fetchData();
  }, [piso]);

  React.useEffect(() => {
    if (refresh) {
      fetchData();
    }
  }, [refresh]);

  return (
    <Box sx={{ width: "100%" }}>
      <Button
        fullWidth
        startIcon={<RiSurveyLine />}
        onClick={() => setOpen(true)}
        variant={"contained"}
      >
        Censo Pacientes
      </Button>
      <BootstrapDialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Censo Pacientes
          <Select
            defaultValue={0}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={piso}
            onChange={(e) => setPiso(e.target.value)}
            sx={{
              border: "solid 	#DCDCDC 1px",
              borderRadius: "5px",
              mb: -1,
              mt: -1,
              position: "absolute",
              right: 60,
              top: 10,
              zIndex: 1,
              color: "white",
            }}
          >
            <MenuItem value={0}>Todos los pisos</MenuItem>
            <MenuItem value={1}>Piso 1</MenuItem>
            <MenuItem value={2}>Piso 2</MenuItem>
            <MenuItem value={3}>Piso 3</MenuItem>
            <MenuItem value={"S1"}>Sótano</MenuItem>
          </Select>
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "5px",
            }}
          >
            {pacientes.map((paciente) => (
              <CardCenso paciente={paciente} />
            ))}
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
};

export default ResumenCenso;

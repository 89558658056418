import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  List,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

import { BootstrapDialog, BootstrapDialogTitle } from "../BootstrapDialog";
import {
  MdDateRange,
  MdLocationOn,
  MdOutlineCleaningServices,
} from "react-icons/md";
import { BiTimer } from "react-icons/bi";
import { FiUser } from "react-icons/fi";
import { RiHealthBookLine } from "react-icons/ri";
import CAMAS from "../../constants/camas.json";
import FichaHabitacion from "./FichaHabitacion";

const ListaHabitaciones = ({ habitaciones }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [params, setParams] = React.useState(null);

  /*calculate the number of pages*/
  const numPages = Math.ceil(habitaciones.length / 10);

  /* paginate the data */
  const data = habitaciones.slice((page - 1) * 10, page * 10);

  React.useEffect(() => {
    setPage(1);
  }, [habitaciones]);

  return (
    <>
      <Grid item xs={12}>
        {/* number of results */}
        <Typography component="p" variant="body2" color="text.primary">
          {`Mostrando ${page > 1 ? page - 1 + "1" : page} - ${
            page * 10 > habitaciones.length ? habitaciones.length : page + "0"
          } de ${habitaciones.length} resultados`}
        </Typography>
      </Grid>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        sx={{ width: "100%" }}
      >
        {data.map((habitacion, index) => (
          <FichaHabitacion
            onClick={() => {
              setOpen(true);
              setParams(habitacion);
            }}
            key={index}
            data={habitacion}
          />
        ))}
      </List>

      <Grid
        container
        item
        xs={12}
        md={12}
        justifyContent={"center"}
        alignContent={"center"}
      >
        <Pagination
          count={numPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </Grid>
      <BootstrapDialog
        open={open}
        fullScreen={fullScreen}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <BootstrapDialogTitle
          onClose={() => setOpen(false)}
          id="form-dialog-title"
        >
          Detalles de la limpieza
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Grid container gap={2} justifyContent="space-between">
            <Chip
              icon={<MdDateRange />}
              label={`Fecha inicio: ${new Date(
                params?.fechaInicio
              ).toLocaleString()}`}
            />
            <Chip
              icon={<MdDateRange />}
              label={`Fecha fin: ${new Date(
                params?.fechaFin
              ).toLocaleString()}`}
            />
            <Chip
              icon={<BiTimer />}
              label={`Tiempo de aseo: ${millisToMinutesAndSecondsShort(
                params?.tiempo
              )}`}
            />
            <Chip icon={<FiUser />} label={`Paciente: ${params?.paciente}`} />
            <Chip
              icon={<FiUser />}
              label={`Responsable de limpieza: ${params?.responsable}`}
            />
            {CAMAS[params?.cama] && (
              <Chip
                icon={<MdLocationOn />}
                label={`Ubicación: ${params?.cama} ${
                  CAMAS[params?.cama]?.Areas
                } ${CAMAS[params?.cama]["piso/area"]}`}
              />
            )}
            <Chip
              icon={<MdOutlineCleaningServices />}
              label={`Tipo de Aseo: ${params?.tipoAseo}`}
            />
            {params?.tipoAlta && (
              <Chip
                color="warning"
                icon={<RiHealthBookLine />}
                label={`Tipo de Alta: ${params?.tipoAlta}`}
              />
            )}
          </Grid>
          <Divider
            sx={{ mt: 2, bgcolor: (theme) => theme.palette.secondary.main }}
          />
          <Typography variant={"h6"} sx={{ mt: 2 }} gutterBottom>
            Comentarios
          </Typography>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Fecha</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Descripción</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Comentarios</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {params?.historial.map((row) => (
                  <TableRow
                    key={row?.fecha}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {new Date(row?.fecha).toLocaleString()}
                    </TableCell>
                    <TableCell>{row?.descripcion}</TableCell>
                    <TableCell
                      sx={{
                        wordWrap: "break-word",
                        whiteSpace: "break-spaces",
                        maxWidth: "300px",
                      }}
                    >
                      {row?.comentarios}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.main + "80",
    cursor: "pointer",
  },
}));

function millisToMinutesAndSecondsShort(s) {
  // Pad to 2 or 3 digits, default is 2
  function pad(n, z) {
    z = z || 2;
    return ("00" + n).slice(-z);
  }

  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;

  return `${hrs > 0 ? pad(hrs) + ":" : "00:"}${
    mins > 0 ? pad(mins) + ":" : "00:"
  }${secs > 0 ? pad(secs) + "" : "00"}`;
}

export default ListaHabitaciones;

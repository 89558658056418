//nombre_personas : valida nombres y apellidos con caracteres especiales
const Regex = {
  nombres_personas:
    /^[a-zA-ZàáâäãåąćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ]+$/u,
  telefono: /^[\d]{10}$/,
  username: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/u,
  password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/u,
  acronimo: /^[a-zA-Z,.'-]+$/u,
};

export default Regex;

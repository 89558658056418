import { TextField } from "@mui/material";
import React from "react";
import { FiSearch } from "react-icons/fi";
import { MdClose } from "react-icons/md";

const ResponsableFiltro = ({ values, setValues }) => {
  const [responsable, setResponsable] = React.useState("");
  React.useEffect(() => {
    setValues({
      ...values,
      responsable: {
        value: responsable,
        filter: (row) =>
          row.responsable.toLowerCase().includes(responsable.toLowerCase()),
      },
    });
  }, [responsable]);

  React.useEffect(() => {
    if (values.reset) {
      setResponsable("");
    }
  }, [values.reset]);

  return (
    <TextField
      fullWidth
      id="outlined-basic"
      label="Buscar por nombre de responsable"
      variant="standard"
      name="nombre"
      value={responsable}
      onChange={(e) => setResponsable(e.target.value)}
      InputProps={{
        startAdornment: <FiSearch style={{ marginRight: "10px" }} />,
        endAdornment: (
          <MdClose
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => setResponsable("")}
          />
        ),
      }}
    />
  );
};

export default ResponsableFiltro;

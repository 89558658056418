import { Alert, IconButton, Snackbar, Typography } from "@mui/material";
import React from "react";
import { FiX } from "react-icons/fi";

const NotificacionSnackbar = ({ notificacionText, cama }) => {
  const [openSnack, setOpenSnack] = React.useState(false);

  React.useEffect(() => {
    if (notificacionText) {
      setOpenSnack(true);
    }
  }, [notificacionText]);
  return (
    <Snackbar
      autoHideDuration={7000}
      onClose={() => setOpenSnack(false)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={openSnack}
    >
      <Alert
        onClose={() => setOpenSnack(false)}
        open={openSnack}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpenSnack(false)}
          >
            <FiX fontSize="small" />
          </IconButton>
        }
        icon={false}
        sx={{ backgroundColor: "#63666A", color: "white" }}
      >
        <Typography variant={"p"}> {notificacionText}</Typography>
        <Typography variant={"p"} sx={{ fontWeight: "bold" }}>
          {" "}
          {cama || ""}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default NotificacionSnackbar;

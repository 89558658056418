import {
  Autocomplete,
  Backdrop,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  Switch,
  Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import React from "react";
import Regex from "../../constants/regex";
import rolArray from "../../constants/rolArray";
import verifyToken from "../../services/verifyToken";
import { BootstrapDialog, BootstrapDialogTitle } from "../BootstrapDialog";
import LabelDialogForm from "../LabelDialogForm";
import ResponseError from "../ResponseError";

const url = process.env.REACT_APP_URL_BACK;

const initialValues = {
  nombre: "",
  apellidoPaterno: "",
  apellidoMaterno: "",
  username: "",
  password: "",
  telefono: "",
  aseguradora: null,
  roles: [],
  estado: false,
};

const EditarUsuarioDialog = ({ open, setOpen, setRefresh, id }) => {
  const [values, setValues] = React.useState(initialValues);
  const [aseguradoras, setAseguradoras] = React.useState([]);
  const [showPassword, setShowPassword] = React.useState(false);

  const [errors, setErrors] = React.useState({});
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const fetchGuardarUsuario = () => {
    setLoading(true);
    verifyToken();
    //validate values
    const validationErrors = validateFields(values);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length !== 0) return setLoading(false);
    if (!values.roles.includes("Aseguradora")) {
      delete values.aseguradora;
    } else {
      values.aseguradora = values?.aseguradora?._id;
    }
    if (!showPassword) {
      delete values.password;
    }

    setError(null);
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+`/api/v1/usuarios/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.errors) {
          setOpen(false);
          setRefresh(true);
          setShowPassword(false);
          setLoading(false);
        }
      });
  };

  React.useEffect(() => {
    verifyToken();
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+`/api/v1/pacientes/form`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAseguradoras(data["aseguradoras"] || []);
      });
  }, []);

  React.useEffect(() => {
    verifyToken();
    if (id) {
      setLoading(true);
      verifyToken();
      const userData = JSON.parse(localStorage.getItem("userData")) || {};
      fetch(url+`/api/v1/pacientes/form`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (userData?.user?.token || ""),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAseguradoras(data["aseguradoras"] || []);

          return fetch(url+`/api/v1/usuarios/${id}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + (userData?.user?.token || ""),
            },
          });
        })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setValues({
            nombre: data["nombre"] || "",
            apellidoPaterno: data["apellidoPaterno"] || "",
            apellidoMaterno: data["apellidoMaterno"] || "",
            telefono: data["telefono"] || "",
            aseguradora: data["aseguradora"] || null,
            username: data["username"] || "",
            roles: data["roles"] || [],
            password: "",
            estado: data["estado"] || "",
          });
          setLoading(false);
        })
        .catch((_error) => {
          console.log("error", _error);
          setError(_error);
          setLoading(false);
        });
    }
  }, [id, open]);

  React.useEffect(() => {
    setValues(initialValues);
  }, [open]);

  return (
    <Grid>
      <Backdrop
        open={loading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 99999999,
        }}
      >
        <CircularProgress />
      </Backdrop>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Editar usuario
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid
            item
            container
            justifyContent={"space-between"}
            xs={12}
            sx={{ flexGrow: 1 }}
          >
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Nombre"} />
              <TextField
                autoComplete="off"
                value={values.nombre}
                error={errors.nombre ? true : false}
                helperText={errors.nombre}
                name="nombre"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Apellido Paterno"} />
              <TextField
                autoComplete="off"
                value={values.apellidoPaterno}
                error={errors.apellidoPaterno ? true : false}
                helperText={errors.apellidoPaterno}
                name="apellidoPaterno"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Apellido Materno"} />
              <TextField
                autoComplete="off"
                value={values.apellidoMaterno}
                error={errors.apellidoMaterno ? true : false}
                helperText={errors.apellidoMaterno}
                name="apellidoMaterno"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Nombre de usuario"} />
              <TextField
                autoComplete="off"
                inputProps={{ maxLength: 50 }}
                value={values.username.trim()}
                error={errors.username ? true : false}
                helperText={errors.username}
                name="username"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              sx={{ m: 1 }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item xs={6}>
                <Typography variant="p">¿Restaurar contraseña?</Typography>
              </Grid>
              <Grid
                container
                item
                xs={6}
                direction={"row"}
                gap={2}
                alignContent="center"
                justifyContent={"center"}
              >
                <Checkbox
                  checked={showPassword}
                  onChange={(e) => setShowPassword(e.target.checked)}
                />
              </Grid>
            </Grid>
            {showPassword && (
              <Grid item xs={12} sx={{ mb: 1 }}>
                <LabelDialogForm name={"Contraseña"} />
                <TextField
                  autoComplete="new-password"
                  value={values.password.trim()}
                  error={errors.password ? true : false}
                  helperText={errors.password}
                  name="password"
                  variant="outlined"
                  fullWidth
                  type="password"
                  onChange={handleChange}
                />
              </Grid>
            )}
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Teléfono"} />
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{"+52"}</InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 10 }}
                autoComplete="off"
                value={values.telefono}
                error={errors.telefono ? true : false}
                helperText={errors.telefono}
                variant="outlined"
                name="telefono"
                fullWidth
                onChange={handleChange}
                type="text"
              />
            </Grid>

            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Rol"} />
              <Select
                value={values.roles}
                onChange={handleChange}
                error={errors.roles ? true : false}
                helperText={errors.roles}
                name="roles"
                multiple
                sx={{
                  mt: 2,
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
                placeholder="Seleccione uno o más roles"
                input={<OutlinedInput id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                    }}
                  >
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                <MenuItem value="" disabled>
                  No hay selección
                </MenuItem>
                {rolArray.map((option, _index) => {
                  return (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={values.roles.indexOf(option) > -1} />
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            {values.roles.includes("Aseguradora") && (
              <Grid item xs={12} sx={{ mb: 1 }}>
                <LabelDialogForm name={"Aseguradora"} />
                <Autocomplete
                  value={values.aseguradora}
                  disablePortal
                  fullWidth
                  id="combo-box-demo"
                  options={aseguradoras}
                  onChange={(_event, newValue) => {
                    setValues({ ...values, aseguradora: newValue });
                  }}
                  getOptionLabel={(option) => {
                    return `${option.acronimo}`;
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={errors.aseguradora ? true : false}
                      helperText={errors.aseguradora}
                      fullWidth
                      placeholder="Seleccione una aseguradora"
                      {...params}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
          <Grid item container xs={12} sx={{ p: 2 }}>
            <Grid item xs={6}>
              <Typography variant="h6">Estado</Typography>
            </Grid>
            <Grid container item xs={6} direction={"row"} gap={2}>
              <Typography variant="h6">Inactivo</Typography>
              <IOSSwitch
                checked={values.estado}
                name="estado"
                onChange={(e) =>
                  setValues({ ...values, estado: e.target.checked })
                }
              />
              <Typography variant="h6">Activo</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ResponseError error={error} />
          <Button onClick={handleClose} variant="outlined">
            Cancelar
          </Button>
          <Button
            onClick={fetchGuardarUsuario}
            variant="contained"
            color="secondary"
          >
            Guardar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Grid>
  );
};

const validateEmpty = (values) => {
  let errors = {};
  Object.keys(values).forEach((key) => {
    if (!values[key]) {
      if (key === "aseguradora" && !values.roles.includes("Aseguradora")) return;
      if (key === "password") return;
      if (key === "estado") return;
      errors[key] = "Campo requerido";
    }
  });
  return errors;
};
function validateFields(values) {
  let errors = validateEmpty(values);

  // check if nombre is all letters
  if (values.nombre && !Regex.nombres_personas.test(values.nombre)) {
    errors.nombre = "El nombre solo puede contener letras";
  }
  if (
    values.apellidoPaterno &&
    !Regex.nombres_personas.test(values.apellidoPaterno)
  ) {
    errors.apellidoPaterno = "El apellido paterno solo puede contener letras";
  }
  // check if nombre is all letters
  if (
    values.apellidoMaterno &&
    !Regex.nombres_personas.test(values.apellidoMaterno)
  ) {
    errors.apellidoMaterno = "El apellido materno solo puede contener letras";
  }
  if (values.telefono && !Regex.telefono.test(values.telefono)) {
    errors.telefono = "El telefono debe contener 10 digitos";
  }

  if (values.roles.includes("Aseguradora") && values.aseguradora === "") {
    errors.aseguradora = "La aseguradora es requerida";
  }

  // check if apellidoPaterno is all letters
  if (values.password && !Regex.password.test(values.password)) {
    errors.password =
      "Formato de contraseña incorrecto, debe contener al menos una letra mayuscula, un numero y ser de al menos 8 caracteres";
  }

  //check if roles is empty
  if (values.roles.length === 0) {
    errors.roles = "Debe seleccionar al menos un rol";
  }

  return errors;
}
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default EditarUsuarioDialog;

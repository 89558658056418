import { Snackbar, Alert, IconButton, Typography } from "@mui/material";
import React from "react";
import { FiX } from "react-icons/fi";

const AsignacionSnackbar = ({ datosLimpieza }) => {
  const [openSnack, setOpenSnack] = React.useState(false);

  React.useEffect(() => {
    if (datosLimpieza) {
      setOpenSnack(true);
    }
  }, [datosLimpieza]);

  return (
    <Snackbar
      autoHideDuration={7000}
      onClose={() => setOpenSnack(false)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={openSnack}
    >
      <Alert
        onClose={() => setOpenSnack(false)}
        open={openSnack}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpenSnack(false)}
          >
            <FiX fontSize="small" />
          </IconButton>
        }
        icon={false}
        sx={{ backgroundColor: "#63666A", color: "white" }}
      >
        <Typography variant={"p"}>
          {" "}
          Se ha asignado correctamente el aseo a
        </Typography>
        <Typography variant={"p"} sx={{ fontWeight: "bold" }}>
          {" "}
          {datosLimpieza?.nombre ? datosLimpieza?.nombre : "N/A"}
        </Typography>
        <Typography variant={"p"}> de la cama</Typography>
        <Typography variant={"p"} sx={{ fontWeight: "bold" }}>
          {" "}
          {datosLimpieza?.cama ? datosLimpieza.cama : "N/A"}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default AsignacionSnackbar;

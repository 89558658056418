import {
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

import FiltrosContainer from "../../../components/Filtros/FiltrosContainer";
import HabitacionFiltro from "../../../components/Filtros/HabitacionFiltro";
import NoCuentaFiltro from "../../../components/Filtros/NoCuentaFiltro";
import NombrePersonaFiltro from "../../../components/Filtros/NombrePersonaFiltro";
import ListaPacientes from "../../../components/ListaPacientes/ListaPacientes";
import logout from "../../../services/logout";

const url = process.env.REACT_APP_URL_BACK;

const PacientesDividido = () => {
  const userRol = JSON.parse(localStorage.getItem("userData"))?.user?.rol;
  const [pacientesSeguros, setPacientesSeguros] = React.useState(null);
  const [pacientesValidacion, setPacientesValidacion] = React.useState(null);
  const [pacientesCobro, setPacientesCobro] = React.useState(null);
  const [filterSeguros, setFilterSeguros] = React.useState(null);
  const [filterValidacion, setFilterValidacion] = React.useState(null);
  const [filterCobro, setFilterCobro] = React.useState(null);

  const [refresh, setRefresh] = React.useState(false);
  const [dataFull, setDataFull] = React.useState(false);

  const fetchData = () => {
    setDataFull(false);
    // fetch data
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+"/api/v1/pacientes/obtenerListasPacientesSeguros", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPacientesCobro(data.pacientesCobro);
        setPacientesSeguros(data.pacientesSeguros);
        setPacientesValidacion(data.pacientesValidacion);
        setFilterCobro(data.pacientesCobro);
        setFilterSeguros(data.pacientesSeguros);
        setFilterValidacion(data.pacientesValidacion);
        setDataFull(true);
      })
      .catch((e) => {
        console.log(e);
        setPacientesCobro(null);
        setPacientesSeguros(null);
        setPacientesValidacion(null);
        setFilterCobro(null);
        setFilterSeguros(null);
        setFilterValidacion(null);
      });
  };

  React.useEffect(() => {
    if (refresh) {
      fetchData();

      setRefresh(false);
    }
  }, [refresh]);

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (pacientesSeguros && !userRol) {
      logout();
    }
  }, [userRol]);
  return (
    <Grid container spacing={4}>
      {/* filters */}

      <Grid item xs={4}>
        {pacientesSeguros && (
          <FiltrosContainer
            data={pacientesSeguros}
            dataFilter={filterSeguros}
            setDataFilter={setFilterSeguros}
            filtros={[NombrePersonaFiltro, HabitacionFiltro, NoCuentaFiltro]}
            ommitComplete
          />
        )}
      </Grid>
      <Grid item xs={4}>
        {pacientesSeguros && (
          <FiltrosContainer
            data={pacientesValidacion}
            dataFilter={filterValidacion}
            setDataFilter={setFilterValidacion}
            filtros={[NombrePersonaFiltro, HabitacionFiltro, NoCuentaFiltro]}
            ommitComplete
          />
        )}
      </Grid>
      <Grid item xs={4}>
        {pacientesSeguros && (
          <FiltrosContainer
            data={pacientesCobro}
            dataFilter={filterCobro}
            setDataFilter={setFilterCobro}
            filtros={[NombrePersonaFiltro, HabitacionFiltro, NoCuentaFiltro]}
            ommitComplete
            fullWidth
          />
        )}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h5" textAlign={"center"} color={"primary"}>
          Seguros
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h5" textAlign={"center"} color={"primary"}>
          Validación de seguros
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h5" textAlign={"center"} color={"primary"}>
          Explicar detalles de cobro
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>

      {/* list */}
      <Grid item xs={3.8}>
        {filterSeguros && (
          <ListaPacientes
            pacientes={filterSeguros}
            refresh={setRefresh}
            tempSize={filterSeguros.length}
            dataFull={dataFull}
            small
          />
        )}
      </Grid>
      <Grid item xs={0.1}>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={3.8}>
        {filterValidacion && (
          <ListaPacientes
            pacientes={filterValidacion}
            refresh={setRefresh}
            tempSize={filterValidacion.length}
            dataFull={dataFull}
            small
          />
        )}
      </Grid>
      <Grid item xs={0.1}>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={3.8}>
        {filterCobro && (
          <ListaPacientes
            pacientes={filterCobro}
            refresh={setRefresh}
            tempSize={filterCobro.length}
            dataFull={dataFull}
            small
          />
        )}
      </Grid>
      {/* show skeleton */}
      {!pacientesSeguros && (
        <Grid
          item
          xs={12}
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
};

export default PacientesDividido;

import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  List,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { FiTrash } from "react-icons/fi";
import { IoSearchSharp } from "react-icons/io5";
import makeRequest from "../../services/makeRequest";
import FichaPaciente from "./FichaPaciente";

const ListaPacientes = ({ pacientes, refresh, tempSize, dataFull, small }) => {
  const [page, setPage] = React.useState(1);
  const [selected, setSelected] = React.useState([]);
  const [activateDelete, setActivateDelete] = React.useState(false);
  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  const isAdmin = userData?.user?.rol.includes("Administrador");
  const activated = isAdmin && activateDelete;
  /*calculate the number of pages*/
  const numPages = Math.ceil(pacientes.length / 10);

  React.useEffect(() => {
    setPage(1);
  }, [pacientes]);

  function handleSelectedItem(e, id) {
    if (e.target.checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((item) => item !== id));
    }
  }

  function checkIfSelected(id) {
    return selected.includes(id);
  }

  function selectAllInPage(page) {
    if (selected.length === pacientes.slice((page - 1) * 10, page * 10).length)
      return setSelected([]);
    const pagePacientes = pacientes.slice((page - 1) * 10, page * 10);
    const pageIds = pagePacientes.map((paciente) => paciente._id);
    setSelected(pageIds);
  }

  async function handleDeleteSelected() {
    const confirmed = window.confirm(
      `¿Está seguro que desea eliminar ${selected.length} pacientes?`
    );
    if (!confirmed) return;

    const ids = selected;
    await makeRequest("/api/v1/pacientes/eliminarPacientes", "DELETE", { ids });
    setSelected([]);
    refresh(true);
  }

  function handleActivateDelete() {
    setActivateDelete(!activateDelete);
    if (activateDelete) setSelected([]);
  }

  return (
    <>
      <Grid
        item
        xs={12}
        container
        direction={"row"}
        alignContent="center"
        gap={2}
        sx={
          selected.length === 0
            ? {}
            : {
                backgroundColor: (theme) => theme.palette.primary.main,
                borderRadius: "1rem",
              }
        }
      >
        {" "}
        <Grid item container alignContent={"center"} xs={12}>
          {isAdmin && (
            <Grid
              item
              container
              justifyContent={"center"}
              alignContent={"center"}
              xs={0.5}
            >
              <Tooltip
                title="Seleccionar pacientes para eliminación"
                placement="top"
              >
                <IconButton onClick={() => handleActivateDelete()}>
                  <FiTrash color="black" />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {activated && (
            <Grid
              item
              container
              justifyContent={"center"}
              alignContent={"center"}
              xs={0.5}
            >
              <Tooltip
                title="Seleccionar todos los pacientes en la página"
                placement="top"
              >
                <Checkbox
                  color="secondary"
                  checked={
                    selected.length ===
                    pacientes?.slice((page - 1) * 10, page * 10).length
                  }
                  onChange={() => selectAllInPage(page)}
                ></Checkbox>
              </Tooltip>
            </Grid>
          )}
          {/* number of results */}
          <Grid item xs={11} container alignContent={"center"}>
            {selected.length === 0 ? (
              <Typography component="p" variant="body2" color="text.primary">
                {`Mostrando ${page > 1 ? page - 1 + "1" : page} - ${
                  page * 10 > tempSize ? tempSize : page + "0"
                } de ${tempSize} resultados`}
              </Typography>
            ) : (
              <Grid container alignContent={"center"}>
                <Grid item xs={5} container alignContent={"center"}>
                  <Typography component="p" variant="body2" color="white">
                    {`${selected.length} Pacientes seleccionados`}
                  </Typography>
                </Grid>
                <Grid xs={6.8} item container justifyContent={"end"}>
                  <Button
                    variant="contained"
                    color={"secondary"}
                    onClick={handleDeleteSelected}
                    startIcon={<FiTrash />}
                  >
                    Eliminar
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!dataFull && <CircularProgress size={"1.2rem"} />}
      </Grid>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        sx={{ width: "100%" }}
      >
        {
          /* render the patients in the current page */
          [...pacientes]
            .sort((a,b) =>
              a?.additionalData?.vip && !b?.additionalData?.vip
              ? -1 :
                !a?.additionalData?.vip  && b?.additionalData?.vip
                ? 1 : 
                  !a?.additionalData?.vip && !b?.additionalData?.vip
                  ? 1 : 0 
            ).sort((a, b) =>
              !a?.additionalData?.tipoAlta && b?.additionalData?.tipoAlta
                ? 1 :
                  !a?.additionalData?.tipoAlta && !b?.additionalData?.tipoAlta
                  ? -1 :
                    !a?.additionalData?.tipoAlta && !b?.additionalData?.tipoAlta
                  ? 1 : 0
            )
            .slice((page - 1) * 10, page * 10)
            .map((paciente, index) => (
              <Grid
                container
                key={index}
                sx={{
                  backgroundColor: checkIfSelected(paciente._id)
                    ? "#e6f7ff"
                    : "",
                  borderRadius: "1rem",
                }}
              >
                {activated && (
                  <Grid
                    item
                    container
                    justifyContent={"center"}
                    alignContent={"center"}
                    xs={0.5}
                  >
                    <Checkbox
                      checked={checkIfSelected(paciente._id)}
                      onChange={(e) => handleSelectedItem(e, paciente._id)}
                    ></Checkbox>
                  </Grid>
                )}
                <Grid item xs={isAdmin ? 11.5 : 12}>
                  <FichaPaciente
                    paciente={paciente}
                    refresh={refresh}
                    small={small}
                  />
                </Grid>
              </Grid>
            ))
        }
      </List>
      {pacientes.length === 0 && (
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ mb: 2 }}
        >
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            xs={12}
          >
            <IoSearchSharp
              style={{ width: "100px", height: "100px", textAlign: "center" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="p"
              variant="p"
              color="text.primary"
              textAlign={"center"}
            >
              No se encontraron resultados
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        item
        xs={12}
        md={12}
        justifyContent={"center"}
        alignContent={"center"}
      >
        <Pagination
          count={numPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </Grid>
    </>
  );
};

export default ListaPacientes;

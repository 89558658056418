import {
  Button,
  ButtonGroup,
  Chip,
  Collapse,
  Divider,
  Grid,
  ListItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { FiEdit } from "react-icons/fi";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { RiUserFollowLine, RiUserUnfollowLine } from "react-icons/ri";
import EditarUsuarioDialog from "../EditarDialogs/EditarUsuarioDialog";

const FichaUsuarios = ({ data, setRefresh }) => {
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const calculateEstatus = (estatus) => {
    let estado = "";
    let icon = <></>;
    let style = {
      width: "3rem",
      height: "3rem",
    };

    if (!estatus?.estado) {
      estado = "Inactivo";
      style.color = "#BABABA";
      icon = <RiUserUnfollowLine style={style} />;
    } else {
      estado = "Activo";
      style.color = "#43B02A";
      icon = <RiUserFollowLine style={style} />;
    }
    return { estado, icon };
  };
  return (
    <Box sx={{ width: "100%" }}>
      <ListItem
        sx={{
          "&:hover": {
            backgroundColor: (theme) => theme.palette.primary.main + "80",
          },
          borderRadius: "10px",
        }}
      >
        <Grid container direction={"row"}>
          <Grid
            item
            sx={{
              mt: "auto",
              mb: "auto",
            }}
            md={1}
            xs={2}
          >
            <Box>{calculateEstatus(data).icon}</Box>
            <Typography
              component="p"
              variant="body2"
              color="text.primary"
              sx={{
                fontStyle: "italic",
                display: {
                  xs: "none",
                  md: "flex",
                  textAlign: "center",
                },
              }}
            >
              {calculateEstatus(data).estado}
            </Typography>
          </Grid>
          {/* clickable box */}
          <Grid
            item
            container
            xs={10}
            md={11}
            sx={{
              cursor: "pointer",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={handleClick}
          >
            <Grid item sx={{ pl: 3 }} md={5} xs={10}>
              <Typography component="p" variant="p" color="text.primary">
                {`${data?.nombre} ${data?.apellidoPaterno} ${data?.apellidoMaterno}`}
              </Typography>

              <Typography component="p" variant="body2" color="text.primary">
                {`Nombre de usuario: ${data?.username || data?.correo}`}
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent={"space-around"}
              gap={2}
              sx={{ display: { xs: "none", md: "flex" }, pl: 2 }}
              md={6}
              xs={0}
            >
              {!open && <ResumenRoles data={data} />}
            </Grid>
            <Grid
              item
              md={1}
              sx={{ ml: "auto" }}
              xs={1}
              container
              justifyContent={"center"}
              alignContent={"center"}
            >
              {open ? <IoIosArrowDropup /> : <IoIosArrowDropdown />}
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{ maxWidth: "100%" }}
      >
        <Grid
          container
          justifyContent={"space-evenly"}
          gap={1}
          sx={{ mt: 2, mb: 2 }}
        >
          <GroupOfChips data={data} />
        </Grid>
        <Grid container>
          <ButtonGroup
            variant="outlined"
            color="primary"
            aria-label="contained primary button group"
            sx={{ ml: 2, mr: 2, mb: 2, mt: 2, width: "100%" }}
          >
            <Button
              startIcon={<FiEdit />}
              sx={{ width: "100%", maxWidth: "100%" }}
              onClick={() => setOpenDialog(true)}
            >
              Editar
            </Button>
          </ButtonGroup>
        </Grid>
      </Collapse>
      <Divider />
      {openDialog && (
        <EditarUsuarioDialog
          open={openDialog}
          setOpen={setOpenDialog}
          id={data.id}
          setRefresh={setRefresh}
        />
      )}
    </Box>
  );
};

const GroupOfChips = ({ data }) => {
  return (
    <>
      {data.roles &&
        data.roles.map((rol) => {
          return (
            <Chip key={rol} label={rol} color="primary" sx={{ mt: 1, mb: 1 }} />
          );
        })}
    </>
  );
};

const ResumenRoles = ({ data }) => {
  let chips = [];
  if (data?.roles) {
    let roles = [...data.roles];
    if (roles.length > 1) {
      if (roles.length > 3) {
        if (data.roles.length - 3 > 1) {
          const rolesAux = roles.slice(0, 3);
          rolesAux.push("... y " + (data.roles.length - 3) + " roles más");
          rolesAux.forEach((rol) => {
            chips.push(
              <Chip
                key={rol}
                label={rol}
                color="primary"
                sx={{ mt: 1, mb: 1 }}
              />
            );
          });
        } else {
          roles.map((rol) => {
            chips.push(
              <Chip
                key={rol}
                label={rol}
                color="primary"
                sx={{ mt: 1, mb: 1 }}
              />
            );
          });
        }
      } else {
        roles.map((rol) => {
          chips.push(
            <Chip key={rol} label={rol} color="primary" sx={{ mt: 1, mb: 1 }} />
          );
        });
      }
    } else {
      chips.push(
        <Chip
          key={roles[0]}
          label={roles[0]}
          color="primary"
          sx={{ mt: 1, mb: 1 }}
        />
      );
    }
  }
  return chips;
};

export default FichaUsuarios;

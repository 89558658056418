import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { MdOutlineAddComment } from "react-icons/md";
import { BootstrapDialog, BootstrapDialogTitle } from "../BootstrapDialog";
import LabelDialogForm from "../LabelDialogForm";
import ResponseError from "../ResponseError";

const url = process.env.REACT_APP_URL_BACK;

const AgregarComentarioDialog = ({ mostrarListaComentarios, data }) => {
  const [openComentarios, setOpenComentarios] = React.useState(false);
  const [comentarios, setComentarios] = React.useState("");
  const [error, setError] = React.useState(null);
  function agregarComentario() {
    // put request
    const obj = {
      idPaciente: data.id,
      comentarios,
    };
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+"/api/v1/limpieza/agregarComentario", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setOpenComentarios(false);
          setComentarios("");
          mostrarListaComentarios(obj.id);
        } else {
          setError(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err.message);
      });
  }
  return (
    <>
      <Tooltip title="Agregar comentarios">
        <IconButton
          onClick={() => {
            setOpenComentarios(true);
          }}
        >
          <MdOutlineAddComment />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        open={openComentarios}
        onClose={() => {
          setOpenComentarios(false);
        }}
        aria-labelledby="customized-dialog-title"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenComentarios(false);
          }}
        >
          {"Agregar comentarios"}
        </BootstrapDialogTitle>
        <DialogContent sx={{ minWidth: "500px" }}>
          <LabelDialogForm name={"Comentarios"} />
          <TextField
            value={comentarios}
            onChange={(e) => {
              setComentarios(e.target.value);
            }}
            fullWidth
          />
          <ResponseError error={error} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setOpenComentarios(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={comentarios.trim().length === 0}
            onClick={() => {
              agregarComentario();
            }}
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default AgregarComentarioDialog;

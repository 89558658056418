import { Button, Grid, Typography } from "@mui/material";
import React from "react";

const PageNotFound = () => {
  return (
    <Grid
      container
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      spacing={3}
    >
      <Grid item xs={12}>
        <Typography variant="h1" sx={{ textAlign: "center" }}>
          404
        </Typography>
        <Typography variant="h2" sx={{ textAlign: "center" }}>
          Esta página no existe
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <img
          style={{
            height: "250px",
          }}
          src={"https://pbs.twimg.com/media/EHmDWJlXYAEhOk-.png"}
          alt="Gato doctor"
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" href="/">
          Volver al inicio
        </Button>
      </Grid>
    </Grid>
  );
};

export default PageNotFound;

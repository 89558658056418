import { Alert, Grid, Snackbar } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const ResponseError = ({ error }) => {
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    setOpen(true);
  }, [error]);
  return (
    <Grid>
      {error && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          open={open}
          autoHideDuration={6000}
        >
          <Alert
            severity="error"
            color="error"
            sx={{ maxWidth: "500px" }}
            onClose={() => {
              setOpen(false);
            }}
          >
            Ha ocurrido un error: {error}
          </Alert>
        </Snackbar>
      )}
    </Grid>
  );
};
ResponseError.propTypes = {
  error: PropTypes.any,
};

export default ResponseError;

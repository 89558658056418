import { CircularProgress, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import socketIOClient from "socket.io-client";
import AsignacionSnackbar from "../../../components/AseoHabitaciones/AsignacionSnackbar";
import HabitacionCard from "../../../components/AseoHabitaciones/HabitacionCard";
import NotificacionSnackbar from "../../../components/AseoHabitaciones/NotificacionSnackbar";
import { HiOutlineSparkles } from "react-icons/hi";
import { FiSearch } from "react-icons/fi";
import { Box } from "@mui/system";
import HabitacionFiltro from "../../../components/Filtros/HabitacionFiltro";
import FiltrosContainer from "../../../components/Filtros/FiltrosContainer";
import EstadoHabitacionFiltro from "../../../components/Filtros/EstadoHabitacionFiltro";

const AseoHabitaciones = () => {
  const [dataRows, setDataRows] = React.useState(null);
  const [rows, setRows] = React.useState(null);
  const [datosLimpieza, setDatosLimpieza] = React.useState(null);
  const [notificacionText, setNotificacionText] = React.useState(null);
  const [cama, setCama] = React.useState(null);
  const [filtroCama, setFiltroCama] = React.useState("");

  React.useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
      transports: ["websocket"],
    });

    socket.emit("camas");

    socket.on("updateCamas", async (data) => {
      data.forEach((row) => {
        Object.keys(row).forEach((key) => {
          if (key.includes("_id")) {
            row["id"] = row[key];
          }
        });
      });
      setDataRows(data || {});
      setRows(data || []);
    });

    socket.on("notificacionRegistro", async (data) => {
      setDatosLimpieza(data);
    });

    socket.on("notificacionLimpieza", async (data) => {
      setNotificacionText(data.notificacion);
      setCama(data.cama);
    });
  }, []);

  return (
    <Grid container spacing={3}>
      {dataRows && (
        <FiltrosContainer
          data={dataRows}
          dataFilter={rows}
          setDataFilter={setRows}
          filtros={[EstadoHabitacionFiltro]}
          FiltroPrincipal={HabitacionFiltro}
          fullWidth
        />
      )}

      {rows &&
        rows.map((row, index) => (
          <Grid item md={6} xs={12} key={row + index}>
            <HabitacionCard habitacion={row} />{" "}
          </Grid>
        ))}
      <AsignacionSnackbar datosLimpieza={datosLimpieza} />
      <NotificacionSnackbar notificacionText={notificacionText} cama={cama} />
      {dataRows?.length === 0 && (
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ mb: 2, mt: 10 }}
        >
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            xs={12}
          >
            <HiOutlineSparkles
              style={{
                width: "100px",
                height: "100px",
                textAlign: "center",
                color: "#808080",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="p"
              variant="p"
              sx={{ color: "#808080", mt: 2 }}
              textAlign={"center"}
            >
              No hay habitaciones para realizar el aseo.
            </Typography>
          </Grid>
        </Grid>
      )}
      {rows?.length === 0 && dataRows?.length !== 0 && (
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ mb: 2, mt: 10 }}
        >
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            xs={12}
          >
            <FiSearch
              style={{
                width: "100px",
                height: "100px",
                textAlign: "center",
                color: "#808080",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="p"
              variant="p"
              sx={{ color: "#808080", mt: 2 }}
              textAlign={"center"}
            >
              No se encontraron habitaciones con ese nombre.
            </Typography>
          </Grid>
        </Grid>
      )}

      {!dataRows && (
        <Grid
          item
          xs={12}
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
};

export default AseoHabitaciones;

import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";
import { BootstrapDialog, BootstrapDialogTitle } from "../BootstrapDialog";

const url = process.env.REACT_APP_URL_BACK;

const ResetPaciente = ({
  datosPaciente,
  setActividadSeleccionada,
  idPaciente,
}) => {
  const [reset, setReset] = React.useState(false);
  function resetPaciente() {
    const token = JSON.parse(localStorage.getItem("userData"));
    fetch(url+"/api/v1/pacientes/reset", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.user.token,
      },
      body: JSON.stringify({
        id: idPaciente,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((msg) => {
        console.log(msg);
        setActividadSeleccionada("probableAlta");
        setReset(false);
        document.getElementById(
          "analisisDeCuentaSubText"
        ).innerHTML = `<tspan x="25" dy="0.85em" text-anchor="middle">
        FDL
      </tspan>`;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      {" "}
      <Button
        variant="contained"
        color="error"
        sx={{ mt: 5 }}
        disabled={!datosPaciente}
        fullWidth
        onClick={() => {
          setReset(true);
        }}
      >
        Cancelar alta del paciente
      </Button>
      <BootstrapDialog
        open={reset}
        onClose={() => setReset(false)}
        aria-labelledby="form-dialog-title"
      >
        <BootstrapDialogTitle
          onClose={() => setReset(false)}
          id="form-dialog-title"
        >
          ¿Cancelar el alta del paciente?
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Typography variant="p" sx={{ textAlign: "center", mt: 10 }}>
            Esta acción reiniciara el proceso de alta del paciente. ¿Estás de
            acuerdo?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => resetPaciente()} color="primary">
            Aceptar
          </Button>
          <Button onClick={() => setReset(false)} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default ResetPaciente;

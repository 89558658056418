import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { MdOutlinePlayArrow } from "react-icons/md";
import verifyToken from "../../services/verifyToken";
import { BootstrapDialog, BootstrapDialogTitle } from "../BootstrapDialog";
import LabelDialogForm from "../LabelDialogForm";
import ResponseError from "../ResponseError";

const url = process.env.REACT_APP_URL_BACK;

const AsignarHabitacionDialog = ({ id }) => {
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState("");
  const [errors, setErrors] = React.useState(null);
  const [comentarios, setComentarios] = React.useState("");
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState(null);

  const handleChange = (event) => {
    setValues(event.target.value);
  };

  const handleClose = () => {
    setComentarios("");
    setValues("");
    setErrors(null);
    setError(null);
    setOpen(false);
  };

  const handleSubmit = (event) => {
    verifyToken();
    event.preventDefault();
    const userData = JSON.parse(localStorage.getItem("userData")) || {};

    //validate values

    const validationErrors = validateFields(values);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length !== 0) return;

    //send request
    fetch(url+"/api/v1/limpieza/registrarCama", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
      body: JSON.stringify({
        idLimpieza: values,
        idPaciente: id,
        comentarios: comentarios,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setError(data.error);
        } else {
          handleClose();
        }
      });
  };

  React.useEffect(() => {
    verifyToken();
    if (open) {
      verifyToken();
      const userData = JSON.parse(localStorage.getItem("userData")) || {};
      fetch(url+`/api/v1/limpieza/listarSinAtender`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (userData?.user?.token || ""),
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((_data) => {
          setData(_data);
        })
        .catch((_error) => {
          console.log("error", _error);
          setError(_error);
        });
    }
  }, [open]);
  return (
    <>
      <Button
        size="small"
        onClick={() => setOpen(true)}
        endIcon={<MdOutlinePlayArrow />}
      >
        Iniciar Aseo
      </Button>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Asignar Colaborador de Limpieza
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid
            item
            container
            justifyContent={"space-between"}
            xs={12}
            sx={{ flexGrow: 1 }}
          >
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Colaborador de limpieza"} />
              <Select
                variant="outlined"
                value={values}
                onChange={handleChange}
                error={errors}
                displayEmpty
                fullWidth
              >
                <MenuItem value="" disabled>
                  No hay selección
                </MenuItem>
                {data.map((option) => (
                  <MenuItem key={"s" + option._id} value={option._id}>
                    {`${option.nombre} ${option.apellidoPaterno} ${option.apellidoMaterno}`}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <LabelDialogForm name={"Comentarios"} />
              <TextField
                value={comentarios}
                onChange={(event) => setComentarios(event.target.value)}
                variant="outlined"
                placeholder="Opcional"
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ResponseError error={error} />
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            onClick={(e) => {
              handleSubmit(e);
            }}
            variant="contained"
            color="secondary"
            disabled={values === ""}
          >
            Guardar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

function validateFields(values) {
  let errors = "";

  if (!values) {
    errors = "Campo requerido";
  }

  return errors;
}
export default AsignarHabitacionDialog;

const rolArray = [
  "Médico",
  "Médico de enlace",
  "Superdoctor",
  "Enfermería",
  "Análisis de Cuenta",
  "Seguros",
  "Aseguradora",
  "Atención Integral",
  "Traslado",
  "Almacén general",
  "Hotelería",
  "Relaciones Públicas",
  "Mantenimiento",
  "Administrador",
  "Nutriólogo",
  "Biomédica",
  "Sistemas",
];

export default rolArray;

import { Chip, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const EstadoPacienteFiltro = ({ values, setValues, allowCompleteFilter }) => {
  const [status, setStatus] = React.useState({
    proceso: false,
    pausa: false,
    completo: false,
  });
  React.useEffect(() => {
    setValues({
      ...values,
      estado: {
        value: status,
        filter: (row) => {
          if (status.proceso) {
            return row.estadoProceso === 1;
          }
          if (status.pausa) {
            return row.estadoProceso === 0;
          }
          if (status.completo) {
            return row.estadoProceso === 2;
          }
          return true;
        },
      },
    });
  }, [status]);

  React.useEffect(() => {
    if (values.reset) {
      setStatus({
        proceso: false,
        pausa: false,
        completo: false,
      });
    }
  }, [values.reset]);

  return (
    <Box>
      {" "}
      <Typography
        component="p"
        variant="body2"
        color="text.primary"
        gutterBottom
      >
        Estatus
      </Typography>
      <Grid container gap={1}>
        <Chip
          label="En Proceso"
          name="proceso"
          color={"enProceso"}
          variant={status.proceso ? "default" : "outlined"}
          onClick={() =>
            setStatus({
              ...status,
              pausa: false,
              completo: false,
              proceso: !status.proceso,
            })
          }
        />
        <Chip
          label="Por iniciar"
          variant={status.pausa ? "default" : "outlined"}
          name="pausa"
          color={"sinIniciar"}
          onClick={() =>
            setStatus({
              ...status,
              proceso: false,
              completo: false,
              pausa: !values.pausa,
            })
          }
        />
        {allowCompleteFilter && (
          <Chip
            label="Completo"
            variant={status.completo ? "default" : "outlined"}
            name="pausa"
            color={"terminado"}
            onClick={() =>
              setStatus({
                ...status,
                proceso: false,

                pausa: false,
                completo: !status.completo,
              })
            }
          />
        )}
      </Grid>
    </Box>
  );
};

export default EstadoPacienteFiltro;

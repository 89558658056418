import { Chip, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import {
  MdCheckCircleOutline,
  MdPauseCircleOutline,
  MdPlayCircleOutline,
} from "react-icons/md";
import CAMAS from "../../constants/camas.json";
import InformacionAdicionalDialog from "./InformacionAdicionalDialog";

const FichaPaciente = ({ datosPaciente }) => {
  const calculateEstatus = (estatus) => {
    let estado = "";
    let icon = <></>;
    let style = {
      width: "3rem",
      height: "3rem",
    };
    if (estatus?.diagrama["complete"].isHere) {
      estado = "Completo";
      icon = <MdCheckCircleOutline style={style} />;
      style.color = "#43B02A";
    } else if (estatus?.estadoProceso === 1) {
      estado = "En proceso";
      icon = <MdPlayCircleOutline style={style} />;
      style.color = "#0072CE";
    } else {
      estado = "Sin iniciar";
      icon = <MdPauseCircleOutline style={style} />;
      style.color = "#E88D21";
    }
    if (!estatus?.estado) {
      estado += " - Desactivado";
      style.color = "#FAFAFA";
    }
    return { estado, icon };
  };
  return (
    <Paper sx={{ width: "100%", p: 2 }}>
      <Grid container item xs={12} spacing={2}>
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          md={1}
          xs={1}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {calculateEstatus(datosPaciente).icon}
          </Box>

          <Grid
            item
            xs={12}
            sx={{
              display: {
                xs: "none",
                md: "block",
              },
            }}
          >
            <Typography
              component="p"
              variant="body2"
              color="text.primary"
              sx={{ fontStyle: "italic", textAlign: "center" }}
            >
              {calculateEstatus(datosPaciente).estado}
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={4} xs={10} container alignContent={"center"}>
          <Grid item xs={12}>
            <Typography component="p" variant="p" color="text.primary">
              {`${datosPaciente?.nombre} ${datosPaciente?.apellidoPaterno} ${datosPaciente?.apellidoMaterno}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="p"
              variant="body2"
              color="text.primary"
              sx={{ fontWeight: "", fontStyle: "italic" }}
            >
              {datosPaciente?.aseguradora?.acronimo || "Sin aseguradora"}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent={"space-around"}
          gap={2}
          md={7}
          xs={12}
        >
          {datosPaciente && (
            <>
              {" "}
              <Chip label={`No de Cuenta: ${datosPaciente?.noCuenta}`} />
              <Chip label={`Habitación: ${datosPaciente?.cama}`} />
              <Chip
                label={`Procedimiento: ${
                  datosPaciente?.diagnostico || "Sin registrar."
                }`}
              />
              {CAMAS[datosPaciente?.cama] && (
                <Chip
                  label={`Ubicación: ${CAMAS[datosPaciente?.cama]["Areas"]} ${
                    CAMAS[datosPaciente?.cama]["piso/area"]
                  }`}
                />
              )}
              {/* render list of medicos */}
              {datosPaciente?.medicos.map((medico) => (
                <Tooltip
                  title={"Correo: " + medico.username}
                  placement="top"
                  key={medico._id}
                >
                  <Chip
                    key={medico._id}
                    label={`Médico: ${medico.nombre} ${medico.apellidoPaterno} ${medico.apellidoMaterno}`}
                  />
                </Tooltip>
              ))}
            </>
          )}

          <Grid item>
            <InformacionAdicionalDialog
              datosPaciente={datosPaciente}
            ></InformacionAdicionalDialog>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FichaPaciente;

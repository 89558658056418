import { TextField } from "@mui/material";
import React from "react";

const CorreoFiltro = ({ values, setValues }) => {
  const [correo, setCorreo] = React.useState("");

  React.useEffect(() => {
    setValues({
      ...values,
      correo: {
        value: correo,
        filter: (row) =>
          row?.correo?.toLowerCase()?.includes(correo.toLowerCase()),
      },
    });
  }, [correo]);

  React.useEffect(() => {
    if (values.reset) {
      setCorreo("");
    }
  }, [values.reset]);

  return (
    <TextField
      id="outlined-basic"
      label="Correo"
      variant="standard"
      fullWidth
      name="correo"
      value={correo}
      onChange={(e) => setCorreo(e.target.value)}
    />
  );
};

export default CorreoFiltro;

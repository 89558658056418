import * as React from "react";
import "../index.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { FiMenu, FiPieChart, FiUsers } from "react-icons/fi";
import { BiLogOut } from "react-icons/bi";
import { RiUserSettingsLine } from "react-icons/ri";
import { MdHelpOutline, MdOutlineCleaningServices } from "react-icons/md";
import { Fab, Grid, IconButton, Tooltip } from "@mui/material";
import { ImWhatsapp } from "react-icons/im";
import logout from "../services/logout";
import Notificaciones from "../components/Notificaciones";
import { FaWhatsapp } from "react-icons/fa";

const drawerWidth = 125;
const Paginas = {
  indicadores: {
    index: 0,
    Icon: FiPieChart,
    label: "Indicadores",
    acceso: ["General"],
    route: "/dashboard",
  },
  catalogoPaciente: {
    index: 1,
    Icon: FiUsers,
    label: "Pacientes",
    acceso: ["General"],
    route: "/catalogoPaciente",
  },
  Limpieza: {
    index: 2,
    Icon: MdOutlineCleaningServices,
    label: `Hotelería`,
    acceso: ["Hotelería"],
    route: "/limpieza",
  },

  configuracion: {
    index: 3,
    Icon: RiUserSettingsLine,
    label: "Configuración",
    acceso: [],
    route: "/configuracion",
  },

  logoutRoute: {
    index: 5,
    Icon: BiLogOut,
    label: "Cerrar Sesión",
    acceso: ["General"],
    action: logout,
  },
};

export default function ClippedDrawer(props) {
  const windowContext = props.window;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const rol = JSON.parse(localStorage.getItem("userData") || {}).user?.rol;
  const nombre = JSON.parse(localStorage.getItem("userData") || {}).user
    ?.nombre;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      {" "}
      <Toolbar />
      <Box sx={{ overflow: "none" }}>
        <List>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <img
              src={"/assets/img/logo-oscuro.png"}
              alt="logo"
              style={{
                width: "100%",
                padding: "10px 10px 10px 10px",
              }}
            />
          </Box>
          <Divider sx={{ mb: 1 }} />
          {Object.keys(Paginas).map((pagina) => {
            const { Icon, label, acceso, route, index, action } =
              Paginas[pagina];
            let activeStyle = {};
            if (index === props.index) {
              activeStyle = {
                backgroundColor: (theme) => theme.palette.primary.main + "80",
                borderRadius: "1rem",
                fontWeight: "bold",
              };
            }
            if (
              acceso.some((permiso) => rol.includes(permiso)) ||
              acceso.includes("General") ||
              rol.includes("Administrador")
            ) {
              return (
                <ListItem
                  key={pagina}
                  disablePadding
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ListItemButton
                    onClick={
                      action ? action : () => (window.location.href = route)
                    }
                    sx={{
                      px: 1,
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      alignItems={"center"}
                      justifyItems={"center"}
                      sx={{ ...activeStyle, py: 1 }}
                    >
                      <Grid item>
                        <Icon style={{ width: "25px", height: "25px" }} />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "0.77rem", textAlign: "center" }}
                        >
                          {label}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItemButton>
                </ListItem>
              );
            } else {
              return <></>;
            }
          })}
        </List>
      </Box>
    </div>
  );
  const container =
    windowContext !== undefined
      ? () => windowContext().document.body
      : undefined;
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 1, display: { sm: "none" } }}
          >
            <FiMenu />
          </IconButton>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <img
              src={"/assets/img/logo-blanco.png"}
              alt="logo"
              style={{
                width: "140px",

                marginTop: 5,
                marginBottom: 5,
                marginRight: 10,
              }}
            />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, pl: 2 }}
          >
            {/* search for index of Paginas */}
            {Paginas[Object.keys(Paginas)[props.index]].label}
          </Typography>

          <Notificaciones />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth + 50,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Fab
        color="primary"
        aria-label="Soporte"
        variant="extended"
        sx={{ position: "fixed", bottom: 20, right: 20 }}
        href={`https://wa.me/5215579721730?text=Buenas%20tardes,%20soy%20${nombre.replace(
          " ",
          "%20"
        )}%20y%20tengo%20un%20problema%20con%20la%20web%20del%20Tracker%20de%20Puerta%20de%20Hierro`}
        target="_blank"
      >
        <FaWhatsapp size={30} style={{ marginRight: 5 }} />
        Soporte
      </Fab>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          maxWidth: {
            xs: "100%",
            md: `calc(100% - ${drawerWidth}px)`,
          },
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}

import { Navigate } from "react-router-dom";
import LoaderToken from "./LoaderToken";

const ProtectedRoute = ({ allowedUsersList, children }) => {
  const actualUserData = JSON.parse(localStorage.getItem("userData") || "{}");

  if (
    !allowedUsersList.some((listItem) =>
      actualUserData?.user?.rol.includes(listItem)
    ) &&
    !allowedUsersList.includes("General")
  ) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <>
      <LoaderToken />
      {children}
    </>
  );
};

export default ProtectedRoute;

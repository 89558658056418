import {
  Backdrop,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import socketIOClient from "socket.io-client";

import { useParams } from "react-router-dom";

import React from "react";

import { ReactComponent as Proceso } from "../assets/paciente.svg";
import { ReactComponent as ProcesoNoA } from "../assets/paciente_noa.svg";
import Historial from "../components/DetallePaciente/Historial";
import DiagramaSwitches from "../components/DiagramaSwitches";
import AccionesTracker from "../components/AccionesTracker";
import ResetPaciente from "../components/DetallePaciente/ResetPaciente";
import FichaPaciente from "../components/DetallePaciente/FichaPaciente";
import TRACKER from "../constants/trackerV2.json";
import checkPisoMedicoEnlace from "../services/checkPisoMedicoEnlace";
const COLORS = ["#fff", "#07d965", "#d9d207", "#F44336"];

let trackerColors = {
  "@keyframes boing": {
    "0%, 20%, 50%, 80%, 100%": { transform: "translateY(0)" },
    "40%": { transform: "translateY(-30px)" },
    "60%": { transform: "translateY(-15px)" },
  },

  "@keyframes dash": { to: { strokeDashoffset: 0 } },
};
const DetallePaciente = () => {
  const { id } = useParams();

  const [historial, setHistorial] = React.useState([]);
  const [diagrama, setDiagrama] = React.useState(false);
  const [datosPaciente, setDatosPaciente] = React.useState(null);
  const [backdrop, setBackdrop] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [displayed, setDisplayed] = React.useState("off");

  const [roles, setRoles] = React.useState([]);
  // Actividades

  const [actividadSeleccionada, setActividadSeleccionada] =
    React.useState(null);
  const [refreshActividadesSwitch, setRefreshActividadesSwitch] =
    React.useState(0);
  React.useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
      transports: ["websocket"],
    });
    socket.emit("tracker", id || "");
    socket.on("updateTracker", (data) => {
      setDatosPaciente(data);
      // sort data historial by fecha
      data.historial.sort((a, b) => {
        return new Date(b.fecha) - new Date(a.fecha);
      });
      clearTracker();
      setHistorial(data.historial);

      setDiagrama(data.diagrama);
      setActividadSeleccionada(data.etapaActual);
      setLoading(false);
      colorTracker(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"));
    if (data) {
      setRoles(data.user.rol);
    }
  }, []);

  React.useEffect(() => {
    if (datosPaciente) {
      clearTracker();
      colorTracker(datosPaciente, displayed);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosPaciente]);

  React.useEffect(() => {
    if (actividadSeleccionada) {
      trackerColors[`& .${TRACKER[actividadSeleccionada].group}Group`] = {};
      if (displayed === "off") {
        setDisplayed("on");
      }
    }
  }, [actividadSeleccionada]);
  return (
    <Grid container direction={"row"} sx={{ width: "100%" }} gap={3}>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      <FichaPaciente datosPaciente={datosPaciente} />
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 1,
            ...trackerColors,
            cursor: "zoom-in",
          }}
          onClick={() => setBackdrop(true)}
        >
          {datosPaciente?.aseguradora ? <Proceso /> : <ProcesoNoA />}
        </Paper>
      </Grid>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          overflowY: "scroll",
        }}
        open={backdrop}
        onClick={() => setBackdrop(false)}
      >
        <Paper
          sx={{
            p: 2,
            ml: 1,
            mr: 1,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            "& svg": {
              width: "2000px",
            },
            ...trackerColors,
            cursor: "zoom-out",
          }}
        >
          {datosPaciente?.aseguradora ? <Proceso /> : <ProcesoNoA />}
        </Paper>
      </Backdrop>
      <Grid item container xs={12} direction={"row"}>
        <Grid item xs={12} md={6} sx={{ pr: { sx: 0, md: 2 }, mb: 2 }}>
          <Historial historial={historial} datosPaciente={datosPaciente} />
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          direction={"column"}
          gap={2}
          alignItems={"stretch"}
          justifyContent={"stretch"}
        >
          {diagrama?.complete?.isHere && (
            <Grid item xs={"auto"}>
              <Grid container item justifyContent={"center"} xs={12}>
                <Paper sx={{ p: 3, width: "100%" }}>
                  <Typography variant="h5" sx={{ textAlign: "center" }}>
                    Alta completa
                  </Typography>
                  <Divider sx={{ m: 2 }} />
                  {historial && (
                    <Typography sx={{ textAlign: "center" }} variant="h6">
                      Duración total del proceso: {getDuracionAlta(historial)}
                    </Typography>
                  )}
                </Paper>
              </Grid>
            </Grid>
          )}
          <Grid item xs={"auto"}>
            {actividadSeleccionada &&
              !datosPaciente?.diagrama?.complete?.isHere &&
              datosPaciente?.estado && (
                <AccionesTracker
                  actividadSeleccionada={actividadSeleccionada}
                  diagrama={diagrama}
                  setRefreshActividadesSwitch={setRefreshActividadesSwitch}
                  setActividadSeleccionada={setActividadSeleccionada}
                  idPaciente={id}
                  datosPaciente={datosPaciente}
                  cama={datosPaciente?.cama}
                />
              )}
          </Grid>
        </Grid>

        <Grid item md={6} xs={12} sx={{ pr: { sx: 0, md: 2 }, mb: 2 }}>
          {(roles.includes("Enfermero") ||
            roles.includes("Administrador") ||
            roles.includes("Médico") ||
            roles.includes("Médico de enlace") ||
            roles.includes("Superdoctor")) &&
            datosPaciente?.estadoProceso === 1 && (
              <ResetPaciente
                datosPaciente={datosPaciente}
                actividadSeleccionada={actividadSeleccionada}
                idPaciente={id}
                setActividadSeleccionada={setActividadSeleccionada}
              />
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

function colorTracker(data, displayed) {
  let completed = 0;

  let checkman = false;
  Object.keys(data.diagrama).forEach((key) => {
    if (data.diagrama[key].estado === 1) {
      trackerColors[`& .${TRACKER[key].group}Group`] = {
        animation: null,
        webkitAnimation: null,
      };
      colorEtapaSatisfactoria(key);
      if (TRACKER[key].completed) {
        if (key === "checklist" && data.diagrama["mantenimiento"].estado !== 1)
          return;
        if (key === "mantenimiento" && data.diagrama["checklist"].estado !== 1)
          return;
        if (key === "checklist" || key === "mantenimiento") {
          if (checkman) return;
          checkman = true;
        }

        colorPath(
          key,
          completed,
          displayed,
          data.diagrama.documentosCobro.isHere && key === "cobro"
        );

        completed++;
      }
    }
    if (data.diagrama[key].isHere) {
      colorEtapaActual(key, data.diagrama[key].estado);
      drawSubText(key);
    }
  });
}

function colorEtapaSatisfactoria(key) {
  trackerColors[`& .${TRACKER[key].group}Circle ellipse`] = {
    fill: (theme) => theme.palette.terminado.main,
  };
  trackerColors[`& .${TRACKER[key].group}Circle`] = {
    fill: (theme) => theme.palette.terminado.main,
    stroke: (theme) => theme.palette.terminado.shade,
  };
  trackerColors[`& .${TRACKER[key].group}Circle>path.cls-3`] = {
    fill: (theme) => theme.palette.terminado.shade,
  };
  trackerColors[`& .${TRACKER[key].group}Text>tspan`] = {
    fill: (theme) => theme.palette.terminado.main,
    fontSize: "13px",
  };
  trackerColors[`& .${TRACKER[key].group}Icon path, rect`] = {
    fill: "white",
  };
  trackerColors[`& .${TRACKER[key].group}Text path`] = {
    fill: (theme) => theme.palette.terminado.main,
  };
}

function colorEtapaActual(key, returned) {
  let colored = "enProceso";
  if (returned === 2) colored = "sinIniciar";
  if (returned === 3) colored = "rechazado";
  trackerColors[`& .${TRACKER[key].group}Circle ellipse`] = {
    fill: (theme) => theme.palette[colored].main,
  };
  trackerColors[`& .${TRACKER[key].group}Circle`] = {
    fill: (theme) => theme.palette[colored].main,
    stroke: (theme) => theme.palette[colored].shade,
  };
  trackerColors[`& .${TRACKER[key].group}Circle>path.cls-3`] = {
    fill: (theme) => theme.palette[colored].shade,
  };
  trackerColors[`& .${TRACKER[key].group}Text>tspan`] = {
    fill: (theme) => theme.palette[colored].main,
    fontSize: "13px",
  };
  trackerColors[`& .${TRACKER[key].group}Circle>path.cls-6`] = {
    fill: (theme) => theme.palette[colored].shade,
  };
  trackerColors[`& .${TRACKER[key].group}Icon path, rect`] = {
    fill: "white",
  };
  trackerColors[`& .${TRACKER[key].group}Text path`] = {
    fill: (theme) => theme.palette[colored].main,
  };
  trackerColors[`& .${TRACKER[key].group}Group`] = {
    animation: "boing 300ms ease-in-out",
  };
}

function colorPath(key, completed, displayed, documentosCobro) {
  const total = 6;
  if (documentosCobro) return;
  trackerColors[`& .${TRACKER[key].completed}`] = {
    stroke: (theme) => theme.palette.terminado.shade,
    strokeDasharray: "1000",
    strokeDashoffset: 1000,
    animation: `dash ${
      displayed === "on" ? "0.2" : total * 0.5
    }s linear forwards ${displayed === "on" ? "" : completed * 0.3 + "s"}`,
  };
}

function drawSubText(key) {
  const title = TRACKER[key].title.split(" ");
  let titleArray = [];
  let titleString = "";
  title.forEach((word) => {
    if (titleString.length + word.length < 10) {
      titleString += word + " ";
    } else {
      titleArray.push(titleString);
      titleString = word + " ";
    }
  });
  titleArray.push(titleString);
  titleArray = titleArray.filter((word) => word.trim() !== "");

  // set every title on a tspan element
  const titleElements = titleArray.map((title, index) => {
    let dy = "0em";
    if (index === 0) dy = "0em";
    else dy = "1.2em";
    if (key === "FDL") dy = "0.85em";
    return `<tspan x="25" dy=${dy}  text-anchor="middle" >
          ${title.trim()}
        </tspan>`;
  });
  // clean all subTexts
  const subTexts = [
    "altaSubText",
    "segurosSubText",
    "cobroSubText",
    "trasladoSubText",
  ];
  for (const element of subTexts) {
    if (!document.getElementById(element)) continue;
    document.getElementById(element).innerHTML = "";
  }
  const subTextObj = document.getElementById(TRACKER[key].group + "SubText");
  if (!subTextObj) return;
  subTextObj.innerHTML = titleElements.toString().replaceAll(",", "");
}

function clearTracker() {
  trackerColors = {
    "@keyframes boing": {
      "0%, 20%, 50%, 80%, 100%": { transform: "translateY(0)" },
      "40%": { transform: "translateY(-30px)" },
      "60%": { transform: "translateY(-15px)" },
    },

    "@keyframes dash": { to: { strokeDashoffset: 0 } },
  };
}

function getDuracionAlta(historial) {
  const historialAlta = historial.slice(
    historial.findIndex(
      (e) =>
        e.idActividad === "paseDeSalidaEnfermeria" ||
        e.idActividad === "paseDeSalidaTraslado"
    ),
    historial.findIndex((e) => e.idActividad === "altaMedicaCompleta") + 1
  );
  const altaMedica = historialAlta.filter(
    (item) => item.idActividad === "altaMedicaCompleta"
  );
  const altaCompleta = historialAlta.filter(
    (e) =>
      e.idActividad === "paseDeSalidaEnfermeria" ||
      e.idActividad === "paseDeSalidaTraslado"
  );

  const subProcesos = historialAlta.filter((item) =>
    item.idActividad.includes("Subproceso")
  );

  // sum duracion of all subProcesos
  const duracionSubProcesos = subProcesos.reduce((acc, item) => {
    return acc + item.duracion;
  }, 0);

  // sum tiempo total
  const duracionTotal =
    new Date(altaCompleta[0].fecha) -
    new Date(altaMedica[0].fecha) +
    duracionSubProcesos;

  return millisToMinutesAndSeconds(duracionTotal);
}
function millisToMinutesAndSeconds(s) {
  // Pad to 2 or 3 digits, default is 2
  function pad(n, z) {
    z = z || 2;
    return ("00" + n).slice(-z);
  }

  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;

  return (
    pad(hrs) + " horas " + pad(mins) + " minutos " + pad(secs) + " segundos."
  );
}

export default DetallePaciente;

import logout from "./logout";

const url = process.env.REACT_APP_URL_BACK;

export default async function verifyToken() {
  const userData = JSON.parse(localStorage.getItem("userData")) || {};

  return fetch(url+`/api/v1/usuarios/verifyToken`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (userData?.user?.token || ""),
    },
  })
    .then((response) => response.json())
    .then((response) => {
      if (response?.errors?.error?.status !== 200) {
        logout();
      }
    })
    .catch((_error) => {
      logout();
    });
}

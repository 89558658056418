import {
  Box,
  Paper,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Grid,
} from "@mui/material";
import BitacoraComentarios from "./BitacoraComentarios";
const COLORS = ["#fff", "#07d965", "#d9d207", "#F44336"];

const Historial = ({ historial, datosPaciente }) => {
  return (
    <Paper sx={{ p: 1 }}>
      <Grid container mb={2} alignItems={"center"}>
        <Grid item xs={9}>
          <Typography
            gutterBottom
            color="primary"
            variant="h3"
            component={"span"}
          >
            Historial
          </Typography>
        </Grid>
        <Grid item xs={3} container justifyContent={"flex-end"}>
          <BitacoraComentarios historial={historial} />
        </Grid>
      </Grid>
      <Divider />
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          maxHeight: "605px",
          overflowY: "scroll",
        }}
      >
        {historial.map((item, index) => (
          <ListItem key={index}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: COLORS[item.estado] }}>
                <div></div>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="p" color="primary" component={"div"}>
                  {item.descripcion}
                </Typography>
              }
              secondary={
                <Box>
                  <Typography component="span" variant="body2" color="primary">
                    {new Date(item.fecha).toLocaleString()}
                  </Typography>
                  <Typography component="p" variant="body2" color="primary">
                    {item.comentarios && item.comentarios.length > 0
                      ? `Comentarios: ${item.comentarios}`
                      : ""}
                  </Typography>
                  {item.idActividad === "altaDefinitiva" && (
                    <Typography
                      gutterBottom
                      color="primary"
                      component="p"
                      variant="body2"
                    >
                      {`Tipo de aseo: ${
                        datosPaciente.diagrama.habitacionAseada?.tipoAseo ||
                        "N/A"
                      }`}
                    </Typography>
                  )}

                  {item.motivo && (
                    <Typography
                      gutterBottom
                      color="primary"
                      variant="p"
                      component="p"
                    >
                      {`Motivo: ${item.motivo}`}
                    </Typography>
                  )}
                  {item.egresoAgil && (
                    <Typography
                      gutterBottom
                      color="primary"
                      variant="p"
                      component="p"
                    >
                      {`El alta fue marcada como egreso agil.`}
                    </Typography>
                  )}
                  {item.saltoFDL && (
                    <Typography
                      gutterBottom
                      color="primary"
                      variant="p"
                      component="p"
                    >
                      {`Se marcó como salto de FDL.`}
                    </Typography>
                  )}
                  {item.mantenimiento && (
                    <Typography
                      gutterBottom
                      color="primary"
                      variant="p"
                      component="p"
                    >
                      {`Se solicitó mantenimiento.`}
                    </Typography>
                  )}
                  {item.duracion && (
                    <Typography
                      gutterBottom
                      color="primary"
                      variant="p"
                      component="p"
                    >
                      {`Duración: ${millisToMinutesAndSeconds(item.duracion)}`}
                    </Typography>
                  )}
                  {item?.tipoAlta && (
                    <Typography
                      gutterBottom
                      color="primary"
                      component="p"
                      variant="body2"
                    >
                      {`Tipo de alta: ${item?.tipoAlta}`}
                    </Typography>
                  )}
                </Box>
              }
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

function millisToMinutesAndSeconds(s) {
  // Pad to 2 or 3 digits, default is 2
  function pad(n, z) {
    z = z || 2;
    return ("00" + n).slice(-z);
  }

  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;

  return (
    pad(hrs) + " horas " + pad(mins) + " minutos " + pad(secs) + " segundos."
  );
}

export default Historial;

import { TextField } from "@mui/material";
import React from "react";
import { MdClose } from "react-icons/md";

const HabitacionFiltro = ({ values, setValues }) => {
  const [habitacion, setHabitacion] = React.useState("");

  React.useEffect(() => {
    setValues({
      ...values,
      habitacion: {
        value: habitacion,
        filter: (row) =>
          row.cama.toLowerCase().includes(habitacion.toLowerCase()),
      },
    });
  }, [habitacion]);

  React.useEffect(() => {
    if (values.reset) {
      setHabitacion("");
    }
  }, [values.reset]);

  return (
    <TextField
      id="outlined-basic"
      label="Buscar por Habitación"
      variant="standard"
      fullWidth
      value={habitacion}
      InputProps={{
        endAdornment: (
          <MdClose
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => setHabitacion("")}
          />
        ),
      }}
      onChange={(e) => setHabitacion(e.target.value)}
    />
  );
};

export default HabitacionFiltro;

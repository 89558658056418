import { Chip, Typography } from "@mui/material";
import React from "react";

const EstadoHabitacionFiltro = ({ values, setValues }) => {
  const [status, setStatus] = React.useState({});

  React.useEffect(() => {
    setValues({
      ...values,
      statusHabitacion: {
        value: status,
        filter: (row) => {
          let flag = false;
          if (status.activo) {
            flag = row.estado === true;
          } else if (status.inactivo) {
            flag = row.estado === false;
          } else {
            flag = true;
          }
          return flag;
        },
      },
    });
  }, [status]);

  React.useEffect(() => {
    if (values.reset) {
      setStatus({});
    }
  }, [values.reset]);

  return (
    <>
      {" "}
      <Typography
        component="p"
        variant="body2"
        color="text.primary"
        gutterBottom
      >
        Estado
      </Typography>
      <Chip
        label="En proceso"
        sx={{ mr: 1 }}
        name="proceso"
        color="success"
        variant={status.activo ? "default" : "outlined"}
        onClick={() =>
          setStatus({
            ...status,
            inactivo: false,
            activo: !status.activo,
          })
        }
      />
      <Chip
        label="Sin Atender"
        variant={status.inactivo ? "default" : "outlined"}
        name="pausa"
        color="error"
        onClick={() =>
          setStatus({
            ...status,
            activo: false,
            inactivo: !status.inactivo,
          })
        }
      />
    </>
  );
};

export default EstadoHabitacionFiltro;

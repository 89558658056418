import {
  CircularProgress,
  SpeedDial,
  SpeedDialAction,
  Typography,
} from "@mui/material";
import React from "react";
import { MdDownload } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";

const url = process.env.REACT_APP_URL_BACK;

const DescargarReporte = () => {
  const [disableDownload, setDisableDownload] = React.useState(false);
  const descargarReporteXLSX = () => {
    const token = JSON.parse(localStorage.getItem("userData"));
    setDisableDownload(true);
    fetch(url + "/api/v1/pacientes/reportecsv", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.user.token,
      },
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Reporte.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
        setDisableDownload(false);
      })
      .catch((err) => {
        console.log(err);
        setDisableDownload(false);
      });
  };
  return (
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      sx={{
        position: "fixed",
        bottom: 90,
        right: 20,
        ".MuiButtonBase-root": disableDownload && {
          backgroundColor: "white",
          width: "200px",
          borderRadius: "2rem",
        },
      }}
      color="default"
      icon={
        disableDownload ? (
          <>
            <CircularProgress />
            <Typography
              variant="p"
              sx={{ textAlign: "center", color: "black", ml: 2 }}
            >
              Descargando...
            </Typography>
          </>
        ) : (
          <MdDownload style={{ width: "35px", height: "35px" }} />
        )
      }
    >
      {!disableDownload && (
        <SpeedDialAction
          tooltipOpen
          sx={{
            ".MuiSpeedDialAction-staticTooltipLabel": {
              width: "200px",
              mt: 2,
            },
          }}
          onClick={() => descargarReporteXLSX()}
          icon={<SiMicrosoftexcel style={{ width: "20px", height: "20px" }} />}
          tooltipTitle={"Descargar reporte XLSX"}
        />
      )}{" "}
    </SpeedDial>
  );
};

export default DescargarReporte;

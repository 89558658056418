import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  SpeedDial,
  SpeedDialAction,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { RiSurveyLine } from "react-icons/ri";
import { BootstrapDialog, BootstrapDialogTitle } from "../BootstrapDialog";
import LabelDialogForm from "../LabelDialogForm";
import ResponseError from "../ResponseError";

import makeRequest from "../../services/makeRequest";
import { FiLayers, FiMessageSquare } from "react-icons/fi";
import CardCenso from "./CardCenso";
import censoObj from "../../constants/censoObj";
import trackerObj from "../../constants/trackerV2.json";

const BitacoraComentarios = ({ historial }) => {
  const [open, setOpen] = React.useState(false);
  const [comentarios, setComentarios] = React.useState([]);
  const [orderType, setOrderType] = React.useState(2);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (historial.length > 0) {
      const indexLastReset = historial.findIndex(
        (item) => item.idActividad === "reset"
      );
      // delete everything after reset
      const historialActual =
        indexLastReset > -1 ? historial.slice(0, indexLastReset) : historial;
      setComentarios(
        historialActual.filter(
          (item) => item.comentarios && item.comentarios.length > 0
        )
      );
    }
  }, [historial]);

  return (
    <>
      <Button
        startIcon={<FiMessageSquare />}
        onClick={() => setOpen(true)}
        variant={"contained"}
      >
        Comentarios
      </Button>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Bitacora de Comentarios
        </BootstrapDialogTitle>
        <DialogContent
          sx={{
            marginTop: -2,
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "5px",
            }}
          >
            <Grid
              item
              container
              xs={12}
              alignItems={"center"}
              sx={{
                borderBottom: "1px solid #3754a8",
                ml: "20px",
                pb: 1,
              }}
            >
              <Grid item xs={6}>
                <Typography
                  variant="p"
                  color={"primary"}
                  fontWeight={"bold"}
                  component={"div"}
                >
                  {comentarios.length} Comentarios
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                textAlign={"right"}
                sx={{
                  pr: 2,
                }}
              >
                <ButtonGroup size="small">
                  <Button
                    variant={orderType === 1 ? "contained" : "outlined"}
                    color="primary"
                    onClick={() => {
                      setOrderType(1);
                      setComentarios(
                        comentarios.sort(
                          (a, b) => new Date(a.fecha) - new Date(b.fecha)
                        )
                      );
                    }}
                  >
                    Más Antiguos
                  </Button>
                  <Button
                    variant={orderType === 2 ? "contained" : "outlined"}
                    color="primary"
                    onClick={() => {
                      setOrderType(2);
                      setComentarios(
                        comentarios.sort(
                          (a, b) => new Date(b.fecha) - new Date(a.fecha)
                        )
                      );
                    }}
                  >
                    Más Recientes
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            {comentarios.length === 0 && (
              <Grid item xs={12} minWidth={500}>
                <Typography variant="h5" textAlign={"center"} component={"div"}>
                  No hay comentarios
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <List
                sx={{
                  bgcolor: "background.paper",
                  maxHeight: "500px",
                  overflowY: "auto",
                }}
              >
                {comentarios.map((item, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText
                        primary={
                          <>
                            <Typography
                              variant="span"
                              color="grey.700"
                              fontWeight={"bold"}
                              component={"span"}
                            >
                              {
                                trackerObj[
                                  item.idActividad.replace("Completa", "")
                                ]?.title
                              }
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              color="grey"
                              ml={2}
                            >
                              {new Date(item.fecha).toLocaleString()}
                            </Typography>
                          </>
                        }
                        secondary={
                          <Box
                            sx={{
                              borderBottom: "1px solid #3754a8",
                              pb: 1,
                            }}
                          >
                            <Typography
                              component="p"
                              variant="body2"
                              color="grey.900"
                              mt={1}
                            >
                              {item.comentarios}
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default BitacoraComentarios;

import { CircularProgress, Grid } from "@mui/material";
import React from "react";

import ListaPacientes from "../../../components/ListaPacientes/ListaPacientes";

import EstadoPacienteFiltro from "../../../components/Filtros/EstadoPacienteFiltro";
import EstatusFiltro from "../../../components/Filtros/EstatusFiltro";
import FiltrosContainer from "../../../components/Filtros/FiltrosContainer";
import HabitacionFiltro from "../../../components/Filtros/HabitacionFiltro";
import NoCuentaFiltro from "../../../components/Filtros/NoCuentaFiltro";
import NombrePersonaFiltro from "../../../components/Filtros/NombrePersonaFiltro";

const url = process.env.REACT_APP_URL_BACK;

const ColeccionPacientes = () => {
  const [pacientes, setPacientes] = React.useState(null);
  const [pacientesFilter, setPacientesFilter] = React.useState(null);
  const [dataFull, setDataFull] = React.useState(false);
  const [tempSize, setTempSize] = React.useState(0);
  const [refresh, setRefresh] = React.useState(false);

  const fetchData = () => {
    setDataFull(false);
    // fetch data
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+"/api/v1/pacientes/listarHistorial", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPacientes(data);
        setPacientesFilter(data);
        setDataFull(true);
      })
      .catch((e) => {
        console.log("ocurrio un error en fetch", e);
        setPacientes(null);
        setPacientesFilter(null);
      });
  };
  const fetchDataTemp = () => {
    // fetch data
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+"/api/v1/pacientes/listarHistorialTemporal", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPacientes(data.pacientes);
        setPacientesFilter(data.pacientes);
        setTempSize(data.total);
        fetchData();
      })
      .catch((e) => {
        console.log(e);
        setPacientes(null);
        setPacientesFilter(null);
      });
  };

  React.useEffect(() => {
    fetchDataTemp();
  }, []);

  React.useEffect(() => {
    if (refresh) {
      fetchDataTemp();

      setRefresh(false);
    }
  }, [refresh]);

  return (
    <Grid container spacing={4}>
      {/* filters */}
      {pacientes && (
        <FiltrosContainer
          data={pacientes}
          dataFilter={pacientesFilter}
          setDataFilter={setPacientesFilter}
          filtros={[
            HabitacionFiltro,
            NoCuentaFiltro,
            EstadoPacienteFiltro,
            EstatusFiltro,
          ]}
          FiltroPrincipal={NombrePersonaFiltro}
          allowCompleteFilter
          fullWidth
        />
      )}

      {/* list */}
      <Grid item xs={12}>
        {pacientesFilter && (
          <ListaPacientes
            pacientes={pacientesFilter}
            refresh={setRefresh}
            tempSize={tempSize}
            dataFull={dataFull}
          />
        )}
      </Grid>
      {/* show skeleton */}
      {!pacientes && (
        <Grid
          item
          xs={12}
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
};

export default ColeccionPacientes;

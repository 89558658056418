import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { BootstrapDialog, BootstrapDialogTitle } from "../BootstrapDialog";

const url = process.env.REACT_APP_URL_BACK;

const AddSubProceso = ({ idPaciente }) => {
  const [open, setOpen] = React.useState(false);
  const [procesos, setProcesos] = React.useState([]);
  const [value, setValue] = React.useState({
    horas: 0,
    minutos: 0
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isNaN(value.minutos)) value.minutos = 0;
    if (isNaN(value.horas)) value.horas = 0;
    const milisegundos = value.horas * 3600000 + value.minutos * 60000;
    const tiempoEstimado = {
      horas: value.horas,
      minutos: value.minutos,
      duracion: milisegundos,
    };
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+ "/api/v1/pacientes/addTiempoEstimado", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
      body: JSON.stringify({
        idPaciente,
        tiempoEstimado
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // reset values
        if (data.error) {
          alert(data.error);
        } else {
          setValue({
            horas: 0,
            minutos: 0
          });
          setOpen(false);
        }
      })
      .catch((errorCatch) => {
        console.log("error", errorCatch);
      });
  };

  return (
    <Grid container alignItems="center" justifyContent={"center"} sx={{ p: 1 }}>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        sx={{ mt: 2 }}
        onClick={() => setOpen(true)}
      >
        Agregar Tiempo Estimado
      </Button>
      <BootstrapDialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setOpen(false)}
        >
          Agregar Tiempo Estimado
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="p">Tiempo de realización</Typography>
            </Grid>

            <Grid item>
              {/* hours textfield */}
              <TextField
                label="Horas"
                type="number"
                value={value.horas}
                onChange={(e) => {
                  const hours = parseInt(e.target.value);
                  // check hours
                  // check if NaN
                  if (hours < 0) {
                    setValue({
                      ...value,
                      horas: 0,
                    });
                  } else setValue({ ...value, horas: hours });
                }}
              />
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: (theme) => theme.palette.secondary.main,
                }}
              >
                :
              </Typography>
            </Grid>
            {/* minutes textfield */}
            <Grid item>
              <TextField
                label="Minutos"
                type="number"
                value={value.minutos}
                onChange={(e) => {
                  const minutes = parseInt(e.target.value);

                  if (minutes > 59) {
                    setValue({
                      ...value,
                      horas: value.horas + 1,
                      minutos: 0,
                    });
                  } else if (minutes < 0) {
                    setValue({
                      ...value,
                      horas: value.horas - 1 < 0 ? 0 : value.horas - 1,
                      minutos: value.minutos + 59,
                    });
                  } else {
                    setValue({ ...value, minutos: minutes });
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={(e) => handleSubmit(e)}
            color="secondary"
            disabled={
              (value.minutos === 0 && value.horas === 0) ||
              (isNaN(value.minutos) && value.horas === 0) ||
              (isNaN(value.horas) && value.minutos === 0) ||
              (isNaN(value.minutos) && isNaN(value.horas))
            }
          >
            Guardar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Grid>
  );
};

export default AddSubProceso;

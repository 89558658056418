import { Grid } from "@mui/material";
import React from "react";

import DescargarReporte from "../components/Dashboard/DescargarReporte";

import PacienteGraficas from "./PacienteGraficas";

const Dashboard = () => {
  const userData = JSON.parse(localStorage.getItem("userData")) || {};

  return (
    <Grid container spacing={2} sx={{ mb: 10 }}>
      <PacienteGraficas userData={userData} />
      {(userData.user.rol.includes("Consulta") ||
        userData.user.rol.includes("Administrador")) && <DescargarReporte />}
    </Grid>
  );
};

export default Dashboard;

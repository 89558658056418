import { Button, CircularProgress, Grid } from "@mui/material";
import React from "react";

import { IoReturnDownBack } from "react-icons/io5";
import { useParams } from "react-router-dom";
import AgregarPacienteDialog from "../../../components/AgregarDialogs/AgregarPacienteDialog";
import EstadoPacienteFiltro from "../../../components/Filtros/EstadoPacienteFiltro";
import EstatusFiltro from "../../../components/Filtros/EstatusFiltro";
import FiltrosContainer from "../../../components/Filtros/FiltrosContainer";
import HabitacionFiltro from "../../../components/Filtros/HabitacionFiltro";
import NoCuentaFiltro from "../../../components/Filtros/NoCuentaFiltro";
import NombrePersonaFiltro from "../../../components/Filtros/NombrePersonaFiltro";
import ListaPacientes from "../../../components/ListaPacientes/ListaPacientes";
import logout from "../../../services/logout";

const url = process.env.REACT_APP_URL_BACK;

const PacientesActividad = () => {
  const { id } = useParams();
  const userRol = JSON.parse(localStorage.getItem("userData"))?.user?.rol;
  const [open, setOpen] = React.useState(false);
  const [pacientes, setPacientes] = React.useState(null);
  const [pacientesFilter, setPacientesFilter] = React.useState(null);
  const [tempSize, setTempSize] = React.useState(0);
  const [refresh, setRefresh] = React.useState(false);
  const [dataFull, setDataFull] = React.useState(false);

  const fetchData = () => {
    setDataFull(false);
    console.log("id", id);
    // fetch data
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    
    fetch(url+"/api/v1/pacientes/listarPacientesActividad", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        setPacientes(data);
        setPacientesFilter(data);
        setDataFull(true);
      })
      .catch((e) => {
        console.log(e);
        setPacientes(null);
        setPacientesFilter(null);
      });
  };

  React.useEffect(() => {
    fetchData();
  }, [id]);

  React.useEffect(() => {
    if (refresh) {
      fetchData();

      setRefresh(false);
    }
  }, [refresh]);

  React.useEffect(() => {
    if (pacientes && !userRol) {
      logout();
    }
  }, [userRol]);

  return (
    <Grid container spacing={4} alignItems={"center"}>
      {/* filters */}

      {pacientes && (
        <FiltrosContainer
          data={pacientes}
          dataFilter={pacientesFilter}
          setDataFilter={setPacientesFilter}
          filtros={[
            HabitacionFiltro,
            NoCuentaFiltro,
            EstadoPacienteFiltro,
            EstatusFiltro,
          ]}
          ommitComplete
          FiltroPrincipal={NombrePersonaFiltro}
        />
      )}

      {
        <Grid item md={3} xs={12}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => (window.location.href = "/catalogoPaciente")}
            fullWidth
            startIcon={<IoReturnDownBack />}
          >
            Regresar a lista de pacientes
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => (window.location.href = "/dashboard")}
            fullWidth
            startIcon={<IoReturnDownBack />}
            sx={{ mt: 1 }}
          >
            Regresar a indicadores
          </Button>
        </Grid>
      }

      {/* list */}
      <Grid item xs={12}>
        {pacientesFilter && (
          <ListaPacientes
            pacientes={pacientesFilter}
            refresh={setRefresh}
            tempSize={tempSize}
            dataFull={dataFull}
          />
        )}
      </Grid>
      {/* show skeleton */}
      {(!pacientes || !dataFull) && (
        <Grid
          item
          xs={12}
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      )}

      <AgregarPacienteDialog
        open={open}
        setOpen={setOpen}
        setRefresh={setRefresh}
      />
    </Grid>
  );
};

export default PacientesActividad;

import { TextField } from "@mui/material";
import React from "react";

const AcronimoFiltro = ({ values, setValues }) => {
  const [acronimo, setAcronimo] = React.useState("");
  React.useEffect(() => {
    setValues({
      ...values,
      acronimo: {
        value: acronimo,
        filter: (row) =>
          row.acronimo.toLowerCase().includes(acronimo.toLowerCase()),
      },
    });
  }, [acronimo]);
  React.useEffect(() => {
    if (values.reset) {
      setAcronimo("");
    }
  }, [values.reset]);

  return (
    <TextField
      id="outlined-basic"
      label="Acrónimo"
      variant="standard"
      fullWidth
      name="acronimo"
      value={acronimo}
      onChange={(e) => setAcronimo(e.target.value)}
    />
  );
};

export default AcronimoFiltro;

import {
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

import FiltrosContainer from "../../../components/Filtros/FiltrosContainer";
import HabitacionFiltro from "../../../components/Filtros/HabitacionFiltro";
import NoCuentaFiltro from "../../../components/Filtros/NoCuentaFiltro";
import NombrePersonaFiltro from "../../../components/Filtros/NombrePersonaFiltro";
import ListaPacientes from "../../../components/ListaPacientes/ListaPacientes";
import logout from "../../../services/logout";

const url = process.env.REACT_APP_URL_BACK;

const PacientesEnCurso = () => {
  const userRol = JSON.parse(localStorage.getItem("userData"))?.user?.rol;
  const [pacientesActivos, setPacientesActivos] = React.useState(null);
  const [pacientesDelDia, setPacientesDelDia] = React.useState(null);

  const [filterActivos, setFilterActivos] = React.useState(null);
  const [filterDelDia, setFilterDelDia] = React.useState(null);

  const [refresh, setRefresh] = React.useState(false);
  const [dataFull, setDataFull] = React.useState(false);

  const fetchData = () => {
    setDataFull(false);
    // fetch data
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+"/api/v1/pacientes/listarPacientesEnCurso", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPacientesActivos(data.pacientesActivos);
        setPacientesDelDia(data.pacientesDelDia);
        setFilterDelDia(data.pacientesDelDia);
        setFilterActivos(data.pacientesActivos);
        setDataFull(true);
      })
      .catch((e) => {
        console.log(e);
        setPacientesActivos(null);
        setPacientesDelDia(null);
        setFilterDelDia(null);
        setFilterActivos(null);
      });
  };

  React.useEffect(() => {
    if (refresh) {
      fetchData();

      setRefresh(false);
    }
  }, [refresh]);

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (pacientesDelDia && !userRol) {
      logout();
    }
  }, [userRol]);
  
  return (
    <Grid container spacing={4}>
      {/* filters */}

      <Grid item xs={6}>
        {pacientesActivos && (
          <FiltrosContainer
            data={pacientesActivos}
            dataFilter={filterActivos}
            setDataFilter={setFilterActivos}
            filtros={[NombrePersonaFiltro, HabitacionFiltro, NoCuentaFiltro]}
            ommitComplete
          />
        )}
      </Grid>
      <Grid item xs={6}>
        {pacientesDelDia && (
          <FiltrosContainer
            data={pacientesDelDia}
            dataFilter={filterDelDia}
            setDataFilter={setFilterDelDia}
            filtros={[NombrePersonaFiltro, HabitacionFiltro, NoCuentaFiltro]}
            ommitComplete
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h5" textAlign={"center"} color={"primary"}>
          Movimientos Recientes
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h5" textAlign={"center"} color={"primary"}>
          Ingresos de hoy
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>

      {/* list */}
      <Grid item xs={5.8}>
        {filterActivos && (
          <ListaPacientes
            pacientes={filterActivos}
            refresh={setRefresh}
            tempSize={filterActivos.length}
            dataFull={dataFull}
            small
          />
        )}
      </Grid>
      <Grid item xs={0.1}>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={5.8}>
        {filterDelDia && (
          <ListaPacientes
            pacientes={filterDelDia}
            refresh={setRefresh}
            tempSize={filterDelDia.length}
            dataFull={dataFull}
            small
          />
        )}
      </Grid>

      {/* show skeleton */}
      {!pacientesDelDia && (
        <Grid
          item
          xs={12}
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
};

export default PacientesEnCurso;

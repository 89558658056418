import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React from "react";
import { useParams } from "react-router-dom";

import { Backdrop, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FiCheck, FiEye, FiEyeOff, FiLock, FiX } from "react-icons/fi";
import ResetPasswordError from "../components/ResetPasswordError";

const url = process.env.REACT_APP_URL_BACK;

const ResetPassword = () => {
  const { id, token } = useParams();
  const [values, setValues] = React.useState({
    password: "",
    passwordConfirmation: " ",
    disabled: true,
    showPassword: false,
    showPasswordConfirmation: false,
  });
  const [passwordPolicy, setPasswordPolicy] = React.useState({
    passwordLength: false,
    passwordUppercase: false,
    passwordLowercase: false,
    passwordNumber: false,
  });
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);

  React.useEffect(() => {
    const passwordPolicyCheck = {
      passwordLength: values.password.length >= 8,
      passwordUppercase: /[A-Z]/.test(values.password),
      passwordLowercase: /[a-z]/.test(values.password),
      passwordNumber: /[0-9]/.test(values.password),
    };
    setPasswordPolicy(passwordPolicyCheck);
  }, [values.password]);

  React.useEffect(() => {
    // check password policy
    if (
      passwordPolicy.passwordLength &&
      passwordPolicy.passwordUppercase &&
      passwordPolicy.passwordLowercase &&
      passwordPolicy.passwordNumber &&
      values.passwordConfirmation === values.password
    ) {
      setValues({ ...values, disabled: false });
    } else {
      setValues({ ...values, disabled: true });
    }
  }, [values, passwordPolicy]);

  React.useEffect(() => {
    fetch(url+`/api/v1/usuarios/resetPassword/${id}/${token}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // reset values
        if (data.error) {
          setError(data.error.message);
        } else {
          setError(null);
        }
      })
      .catch((errorCatch) => {
        console.log("error", errorCatch);
        setError(errorCatch?.message);
      });
  }, []);

  const resetPassword = () => {
    fetch(url+`/api/v1/usuarios/resetPassword/${id}/${token}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: values.password,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // reset values
        if (data.error) {
          setError(data.error.message);
        } else {
          setSuccess(data.message);
        }
      })
      .catch((errorCatch) => {
        console.log("error", errorCatch);
        setError(errorCatch?.message);
      });
  };
  return error ? (
    <ResetPasswordError />
  ) : (
    <Grid
      container
      component="main"
      className="bgLogin"
      style={{
        background:
          "url(/assets/img/login.png) center center rgba(90, 42, 130, 0.8)",
        backgroundSize: "cover",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        container
        justifyContent={"center"}
        alignContent={"center"}
      >
        <Paper
          sx={{
            p: 5,
            "@keyframes success": {
              "0%": {
                transform: "scale(1)",
              },
              "50%": {
                transform: "scale(1.1)",
              },
              "100%": {
                transform: "scale(1)",
              },
            },
          }}
          elevation={6}
        >
          <Typography variant="h5">
            {" "}
            Una buena contraseña debe tener:
          </Typography>
          <ul>
            <Grid
              item
              container
              sx={{
                color: passwordPolicy.passwordLength ? "green" : "red",
                animation: passwordPolicy.passwordLength
                  ? "success 1s"
                  : "none",
              }}
              alignItems={"center"}
              spacing={1}
            >
              <Grid item sx={{ mt: "4px" }}>
                {passwordPolicy.passwordLength ? <FiCheck /> : <FiX></FiX>}
              </Grid>
              <Grid item>
                <Typography variant="body1"> Al menos 8 caracteres</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              sx={{
                color: passwordPolicy.passwordUppercase ? "green" : "red",
                animation: passwordPolicy.passwordUppercase
                  ? "success 1s"
                  : "none",
              }}
              alignItems={"center"}
              spacing={1}
            >
              <Grid item sx={{ mt: "4px" }}>
                {passwordPolicy.passwordUppercase ? <FiCheck /> : <FiX></FiX>}
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {" "}
                  Al menos una letra mayúscula
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              sx={{
                color: passwordPolicy.passwordLowercase ? "green" : "red",
                animation: passwordPolicy.passwordLowercase
                  ? "success 1s"
                  : "none",
              }}
              alignItems={"center"}
              spacing={1}
            >
              <Grid item sx={{ mt: "4px" }}>
                {passwordPolicy.passwordLowercase ? <FiCheck /> : <FiX></FiX>}
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {" "}
                  Al menos una letra minúscula
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              sx={{
                color: passwordPolicy.passwordNumber ? "green" : "red",
                animation: passwordPolicy.passwordNumber
                  ? "success 1s"
                  : "none",
              }}
              alignItems={"center"}
              spacing={1}
            >
              <Grid item sx={{ mt: "4px" }}>
                {passwordPolicy.passwordNumber ? <FiCheck /> : <FiX></FiX>}
              </Grid>
              <Grid item>
                <Typography variant="body1"> Al menos un número</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              sx={{
                color:
                  values.password === values.passwordConfirmation
                    ? "green"
                    : "red",
                animation:
                  values.password === values.passwordConfirmation
                    ? "success 1s"
                    : "none",
              }}
              alignItems={"center"}
              spacing={1}
            >
              <Grid item sx={{ mt: "4px" }}>
                {values.password === values.passwordConfirmation ? (
                  <FiCheck />
                ) : (
                  <FiX></FiX>
                )}
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {" "}
                  Las contraseñas deben coincidir
                </Typography>
              </Grid>
            </Grid>
          </ul>
        </Paper>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        justifyContent={"center"}
        alignContent={"center"}
        sx={{
          backgroundColor: "rgba(90, 42, 130, 0.7)",
          color: "white",
        }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            style={{ margin: "auto auto 50px auto", width: "200px" }}
            src="/assets/img/logo-blanco.png"
            alt=""
          />
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <FiLock />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reestablecer contraseña
          </Typography>
          <Box
            sx={{
              mt: 3,
            }}
          >
            <TextField
              margin="normal"
              required
              value={values.password}
              onChange={(e) =>
                setValues({ ...values, password: e.target.value.trim() })
              }
              fullWidth
              id="password"
              placeholder="Contraseña"
              name="password"
              type={values.showPassword ? "text" : "password"}
              autoFocus
              className="form-control form-control-login"
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setValues({
                        ...values,
                        showPassword: !values.showPassword,
                      })
                    }
                    sx={{ mr: 0.5 }}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {values.showPassword ? <FiEyeOff /> : <FiEye />}
                  </IconButton>
                ),
              }}
              sx={{
                fontSize: "12px",
                marginTop: "15px",
                border: "1px solid rgba(90, 42, 130, 0.7)",
                borderRadius: "3rem",
                color: "#9c9c9c",
                backgroundColor: "#FFF",
                "& .Mui-focused": {
                  borderRadius: "3rem",
                },
                "& :hover": {
                  borderRadius: "3rem",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "3rem",
                  },
                },
              }}
            />
            <TextField
              margin="normal"
              required
              value={values.passwordConfirmation.trim()}
              onChange={(e) =>
                setValues({
                  ...values,
                  passwordConfirmation: e.target.value.trim(),
                })
              }
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setValues({
                        ...values,
                        showPasswordConfirmation:
                          !values.showPasswordConfirmation,
                      })
                    }
                    sx={{ mr: 0.5 }}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {values.showPasswordConfirmation ? <FiEyeOff /> : <FiEye />}
                  </IconButton>
                ),
              }}
              fullWidth
              name="passwordConfirmation"
              placeholder="Confirmar contraseña"
              type={values.showPasswordConfirmation ? "text" : "password"}
              id="passwordConfirmation"
              sx={{
                fontSize: "12px",
                marginTop: "15px",
                border: "1px solid rgba(90, 42, 130, 0.7)",
                borderRadius: "3rem",
                color: "#9c9c9c",
                backgroundColor: "#FFF",
                "& .Mui-focused": {
                  borderRadius: "3rem",
                },
                "& :hover": {
                  borderRadius: "3rem",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "3rem",
                  },
                },
              }}
            />
          </Box>
          <Button
            fullWidth
            disabled={values.disabled}
            variant="contained"
            color="secondary"
            sx={{ mt: 3, mb: 2 }}
            onClick={resetPassword}
          >
            Reestablecer contraseña
          </Button>
        </Box>
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={success}
      >
        <Paper
          sx={{
            p: 3,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "green" }}>
            <FiCheck />
          </Avatar>
          <Typography variant="h5" component="h3">
            Contraseña reestablecida correctamente
          </Typography>
          <Typography variant="body2" component="p">
            Ya puedes iniciar sesión con tu nueva contraseña
          </Typography>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Iniciar sesión
          </Button>
        </Paper>
      </Backdrop>
    </Grid>
  );
};

export default ResetPassword;

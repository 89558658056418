import React from "react";
import { TextField } from "@mui/material";
import { FiSearch, FiX } from "react-icons/fi";
import { MdClose } from "react-icons/md";

const NombrePersonaFiltro = ({ values, setValues }) => {
  const [nombre, setNombre] = React.useState("");

  React.useEffect(() => {
    setValues({
      ...values,
      nombre: {
        value: nombre,
        filter: (row) => {
          return `${row.nombre} ${row.apellidoPaterno} ${row.apellidoMaterno}`
            .toLowerCase()
            .includes(nombre.toLowerCase());
        },
      },
    });
  }, [nombre]);

  React.useEffect(() => {
    if (values.reset) {
      setNombre("");
    }
  }, [values.reset]);

  return (
    <TextField
      fullWidth
      id="outlined-basic"
      label="Buscar por nombre"
      variant="standard"
      value={nombre}
      onChange={(e) => setNombre(e.target.value)}
      name="nombre"
      InputProps={{
        startAdornment: <FiSearch style={{ marginRight: "10px" }} />,
        endAdornment: (
          <MdClose
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => setNombre("")}
          />
        ),
      }}
    />
  );
};

export default NombrePersonaFiltro;

import { Button, CircularProgress, Grid } from "@mui/material";
import React from "react";
import { FiPlus } from "react-icons/fi";

import AgregarAseguradoraDialog from "../../../components/AgregarDialogs/AgregarAseguradoraDialog";
import AcronimoFiltro from "../../../components/Filtros/AcronimoFiltro";
import EstatusFiltro from "../../../components/Filtros/EstatusFiltro";
import FiltrosContainer from "../../../components/Filtros/FiltrosContainer";
import NombreOnlyFiltro from "../../../components/Filtros/NombreOnlyFiltro";
import FichaAseguradora from "../../../components/ListaAseguradoras/FichaAseguradora";
import ListaUsuarios from "../../../components/ListaUsuarios/ListaUsuarios";

const url = process.env.REACT_APP_URL_BACK;

const Aseguradoras = () => {
  const [open, setOpen] = React.useState(false);
  const [aseguradoras, setAseguradoras] = React.useState(null);
  const [aseguradorasFilter, setAseguradorasFilter] = React.useState(null);
  const [aseguradorasAux, setAseguradorasAux] = React.useState(null);
  const [nombre, setNombre] = React.useState("");
  const [refresh, setRefresh] = React.useState(false);
  React.useEffect(() => {
    if (refresh) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = () => {
    // fetch data
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+"/api/v1/aseguradoras/listar", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // convert idXXXXXX property to id
        // console.log(data);
        data.forEach((row) => {
          Object.keys(row).forEach((key) => {
            if (key.includes("_id")) {
              row["id"] = row[key];
            }
          });
        });
        setAseguradoras(data);
        setAseguradorasFilter(data);
        setAseguradorasAux(data);
      })
      .catch((e) => {
        console.log(e);
        setAseguradoras(null);
        setAseguradorasFilter(null);
        setAseguradorasAux(null);
      });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (aseguradoras) {
      // filter by name
      if (nombre) {
        setAseguradoras(
          aseguradoras.filter((aseguradora) =>
            `${aseguradora.nombre}`.toLowerCase().includes(nombre.toLowerCase())
          )
        );
      } else {
        setAseguradoras(aseguradorasAux);
      }
    }
  }, [nombre]);

  return (
    <Grid container spacing={4}>
      {/* filters */}

      {aseguradoras && (
        <FiltrosContainer
          data={aseguradoras}
          dataFilter={aseguradorasFilter}
          setDataFilter={setAseguradorasFilter}
          filtros={[AcronimoFiltro, EstatusFiltro]}
          FiltroPrincipal={NombreOnlyFiltro}
        />
      )}

      <Grid item md={3} xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setOpen(true)}
          fullWidth
          startIcon={<FiPlus />}
        >
          Agregar nuevo
        </Button>
      </Grid>

      {/* list */}
      <Grid item xs={12}>
        {aseguradorasFilter && (
          <ListaUsuarios
            data={aseguradorasFilter}
            ListComponent={FichaAseguradora}
            setRefresh={setRefresh}
          />
        )}
      </Grid>
      {/* show skeleton */}
      {!aseguradoras && (
        <Grid
          item
          xs={12}
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      )}
      <AgregarAseguradoraDialog
        setOpen={setOpen}
        open={open}
        setRefresh={setRefresh}
      />
    </Grid>
  );
};

export default Aseguradoras;

import {
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

import FiltrosContainer from "../../../components/Filtros/FiltrosContainer";
import HabitacionFiltro from "../../../components/Filtros/HabitacionFiltro";
import NoCuentaFiltro from "../../../components/Filtros/NoCuentaFiltro";
import NombrePersonaFiltro from "../../../components/Filtros/NombrePersonaFiltro";
import ListaPacientes from "../../../components/ListaPacientes/ListaPacientes";
import logout from "../../../services/logout";

const url = process.env.REACT_APP_URL_BACK;

const PacientesDivididoAnalisisCuenta = () => {
  const userRol = JSON.parse(localStorage.getItem("userData"))?.user?.rol;
  const [pacientesAnalisisCuenta, setPacientesAnalisisCuenta] =
    React.useState(null);
  const [pacientesAjusteCuenta, setPacientesAjusteCuenta] =
    React.useState(null);

  const [filterAnalisisCuenta, setFilterAnalisisCuenta] = React.useState(null);
  const [filterAjusteCuenta, setFilterAjusteCuenta] = React.useState(null);

  const [refresh, setRefresh] = React.useState(false);
  const [dataFull, setDataFull] = React.useState(false);

  const fetchData = () => {
    setDataFull(false);
    // fetch data
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+"/api/v1/pacientes/obtenerListasPacientesAnalisisCuenta", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPacientesAnalisisCuenta(data.pacientesAnalisisCuenta);
        setPacientesAjusteCuenta(data.pacientesAjusteCuenta);
        setFilterAjusteCuenta(data.pacientesAjusteCuenta);
        setFilterAnalisisCuenta(data.pacientesAnalisisCuenta);
        setDataFull(true);
      })
      .catch((e) => {
        console.log(e);
        setPacientesAnalisisCuenta(null);
        setPacientesAjusteCuenta(null);
        setFilterAjusteCuenta(null);
        setFilterAnalisisCuenta(null);
      });
  };

  React.useEffect(() => {
    if (refresh) {
      fetchData();

      setRefresh(false);
    }
  }, [refresh]);

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (pacientesAjusteCuenta && !userRol) {
      logout();
    }
  }, [userRol]);
  return (
    <Grid container spacing={4}>
      {/* filters */}

      <Grid item xs={6}>
        {pacientesAnalisisCuenta && (
          <FiltrosContainer
            data={pacientesAnalisisCuenta}
            dataFilter={filterAnalisisCuenta}
            setDataFilter={setFilterAnalisisCuenta}
            filtros={[NombrePersonaFiltro, HabitacionFiltro, NoCuentaFiltro]}
            ommitComplete
          />
        )}
      </Grid>
      <Grid item xs={6}>
        {pacientesAjusteCuenta && (
          <FiltrosContainer
            data={pacientesAjusteCuenta}
            dataFilter={filterAjusteCuenta}
            setDataFilter={setFilterAjusteCuenta}
            filtros={[NombrePersonaFiltro, HabitacionFiltro, NoCuentaFiltro]}
            ommitComplete
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h5" textAlign={"center"} color={"primary"}>
          Análisis de cuenta
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h5" textAlign={"center"} color={"primary"}>
          Ajuste de cuenta
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>

      {/* list */}
      <Grid item xs={5.8}>
        {filterAnalisisCuenta && (
          <ListaPacientes
            pacientes={filterAnalisisCuenta}
            refresh={setRefresh}
            tempSize={filterAnalisisCuenta.length}
            dataFull={dataFull}
            small
          />
        )}
      </Grid>
      <Grid item xs={0.1}>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={5.8}>
        {filterAjusteCuenta && (
          <ListaPacientes
            pacientes={filterAjusteCuenta}
            refresh={setRefresh}
            tempSize={filterAjusteCuenta.length}
            dataFull={dataFull}
            small
          />
        )}
      </Grid>

      {/* show skeleton */}
      {!pacientesAjusteCuenta && (
        <Grid
          item
          xs={12}
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
};

export default PacientesDivididoAnalisisCuenta;

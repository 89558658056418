import {
  Autocomplete,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { RiFolderUserLine } from "react-icons/ri";
import alergiasObj from "../../constants/alergias.json";
import dietasArray from "../../constants/dietas.json";
import Regex from "../../constants/regex";
import makeRequest from "../../services/makeRequest";
import { BootstrapDialogLg, BootstrapDialogTitle } from "../BootstrapDialog";
import ResponseError from "../ResponseError";

const AgregarDetallesAdicionalesDialog = ({
  id,
  additionalData,
  isOnFicha,
  overrideLoad,
  setDataPaciente,
  datosPaciente,
}) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [fetching, setFetching] = React.useState(false);
  const userRol = JSON.parse(localStorage.getItem("userData"))?.user?.rol;

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickChip = (label) => {
    setData({
      ...data,
      [label]: !data[label],
    });
  };

  const handleSave = async () => {
    if (fetching) return;
    setErrors({});
    const validationErrors = validateFields(data);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length !== 0) return;
    // trim alergias and dieta
    data["dieta"] = data["dieta"]?.trim();
    setFetching(true);
    const response = await makeRequest(
      "/api/v1/pacientes/editAdditionalInfo",
      "PUT",
      {
        id,
        data,
      }
    );
    if (response.error) {
      setError(response.error);
      setFetching(false);

      return;
    }
    if (setDataPaciente) {
      setDataPaciente({ ...datosPaciente, additionalData: data });
    }
    setOpen(false);
    setFetching(false);
  };

  React.useEffect(() => {
    if (!additionalData && overrideLoad) return;
    setData(additionalData);
  }, [additionalData]);

  return (
    <Box sx={{ width: "100%" }}>
      <Button
        fullWidth
        startIcon={<RiFolderUserLine />}
        onClick={() => setOpen(true)}
        variant={isOnFicha ? "outlined" : "contained"}
      >
        {additionalData ? "Editar" : "Agregar"} información adicional
      </Button>
      <BootstrapDialogLg
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {additionalData ? "Editar" : "Agregar"} información adicional
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid container spacing={2} minWidth={400} >
          {(userRol?.includes("Administrador") ||
            userRol?.includes("Enfermería")) && (
              <Grid item container gap={1} justifyContent={"center"} xs={12}>
                <Chip
                  label="Demandante"
                  color="secondary"
                  variant={data?.demandante ? "default" : "outlined"}
                  onClick={() => handleClickChip("demandante")}
                />
                <Chip
                  label="VIP"
                  color="secondary"
                  variant={data?.vip ? "default" : "outlined"}
                  onClick={() => handleClickChip("vip")}
                />
                <Chip
                  label="Inmuno-contagioso"
                  color="secondary"
                  variant={data?.inmunoContagioso ? "default" : "outlined"}
                  onClick={() => handleClickChip("inmunoContagioso")}
                />
                <Chip
                  label="Riesgo de caída"
                  color="secondary"
                  variant={data?.riesgoCaida ? "default" : "outlined"}
                  onClick={() => handleClickChip("riesgoCaida")}
                />
                <Chip
                  label="Paciente grave"
                  color="secondary"
                  variant={data?.pacienteGrave ? "default" : "outlined"}
                  onClick={() => handleClickChip("pacienteGrave")}
                />
              </Grid>
          )}
          {(userRol?.includes("Administrador") ||
            userRol?.includes("Enfermería")) && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
            <Grid item xs={12} marginTop={1}>
              <Autocomplete
                id="combo-box-demo"
                options={dietasArray}
                value={data?.dieta || ""}
                onChange={(event, newValue) => {
                  setData({
                    ...data,
                    dieta: newValue,
                  });
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField {...params} label="Dieta" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="tags-standard"
                options={alergiasObj}
                getOptionLabel={(option) => option.nombre}
                value={data?.alergias || []}
                onChange={(event, newValue) => {
                  if (
                    !newValue[newValue.length - 1]?.nombre &&
                    newValue.length > 0
                  ) {
                    newValue[newValue.length - 1] = {
                      nombre: newValue[newValue.length - 1].trim(),
                      group: "Otras",
                    };
                  }
                  setData({
                    ...data,
                    alergias: [...newValue],
                  });
                }}
                groupBy={(option) => option.group}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Alérgias"
                    placeholder="Alérgias"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ResponseError error={error} />
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleSave()}
            disabled={fetching}
          >
            Guardar
          </Button>
        </DialogActions>
      </BootstrapDialogLg>
    </Box>
  );
};

function validateFields(values) {
  let errors = {};

  // check if data.dieta and data.alergias are valid
  if (values.dieta && !Regex.nombres_personas.test(values.dieta))
    errors.dieta = "El tipo de dieta no es válido";

  return errors;
}
export default AgregarDetallesAdicionalesDialog;

import { Button, Grid } from "@mui/material";
import React from "react";

const url = process.env.REACT_APP_URL_BACK;

const DeshabilitarHabitacion = ({ cama, idPaciente, diagrama }) => {
  const [fetching, setFetching] = React.useState(false);
  const handleDeshabilitar = () => {
    const token = JSON.parse(localStorage.getItem("userData"));
    setFetching(true);
    fetch(url+"/api/v1/limpieza/mantenimiento", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.user.token,
      },
      body: JSON.stringify({
        idPaciente: idPaciente,
        cama: cama,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((msg) => {
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Grid container alignItems="center" justifyContent={"center"} sx={{ p: 1 }}>
      <Button
        variant="contained"
        color="error"
        disabled={fetching}
        fullWidth
        onClick={handleDeshabilitar}
      >
        {diagrama?.mantenimiento?.estado === 3 ? "Habilitar" : "Deshabilitar"}{" "}
        Habitacion
      </Button>
    </Grid>
  );
};

export default DeshabilitarHabitacion;

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { BiX } from "react-icons/bi";
import { GrContactInfo } from "react-icons/gr";
import { RiFileUserLine } from "react-icons/ri";
import AgregarDetallesAdicionalesDialog from "../AccionesTracker/AgregarDetallesAdicionalesDialog";
import Censo from "../AccionesTracker/Censo";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: (theme) => theme.palette.secondary.main,
        color: (theme) => theme.palette.secondary.contrastText,
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <BiX />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const chips = [
// 	'inmunoContagioso',
// 	'demandante',
// 	'vip',
// 	'pacienteGrave',
// 	'riesgoCaida',
// ];

const chips = {
  inmunoContagioso: {
    label: "Inmuno Contagioso",
  },
  demandante: {
    label: "Demandante",
  },
  vip: {
    label: "VIP",
  },
  pacienteGrave: {
    label: "Paciente Grave",
  },
  riesgoCaida: {
    label: "Riesgo Caída",
  },
};
const InformacionAdicionalDialog = ({
  datosPaciente,
  isOnFicha,
  setDataPaciente,
  setRefresh,
}) => {
  const userRol = JSON.parse(localStorage.getItem("userData"))?.user?.rol;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openAdditionalDataModal, setOpenAdditionalDataModal] =
    React.useState(false);
  const handleOpen = () => {
    setOpenAdditionalDataModal(true);
  };
  const handleClose = () => {
    setOpenAdditionalDataModal(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Button
        variant={isOnFicha ? "outlined" : "contained"}
        fullWidth={isOnFicha || false}
        onClick={handleOpen}
        startIcon={<RiFileUserLine />}
      >
        Información Adicional
      </Button>
      <BootstrapDialog
        open={openAdditionalDataModal}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        fullScreen={fullScreen}
        TransitionComponent={Transition}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Información Adicional
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid
            width={"100%"}
            paddingY={1}
            item
            xs={12}
            container
            justifyContent={"space-around"}
          >
            {Object.keys(chips).map((dataName, index) => {
              let style = "outlined";
              if (!datosPaciente?.additionalData) {
                style = "outlined";
              } else if (datosPaciente?.additionalData[dataName]) {
                style = "default";
              } else {
                style = "outlined";
              }
              return (
                <Chip
                  sx={{ m: 0.2 }}
                  key={`${dataName}${index}`}
                  label={chips[dataName]?.label}
                  color="secondary"
                  variant={style}
                  // onClick={() => handleClickChip('vip')}
                />
              );
            })}
          </Grid>
          <Grid paddingY={1} container justifyContent={"space-around"}>
            <Grid item container xs={12} paddingBottom={1.5}>
              <Typography fontWeight={"bold"} variant="span" width={"100%"}>
                Dietas
              </Typography>
              <Typography variant="span" width={"100%"}>
                {datosPaciente?.additionalData?.dieta
                  ? datosPaciente?.additionalData?.dieta
                  : "No registradas"}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Typography fontWeight={"bold"} variant="span" width={"100%"}>
                Alergias
              </Typography>
              <Typography variant="span" width={"100%"}>
                {datosPaciente?.additionalData?.alergias
                  ? datosPaciente?.additionalData?.alergias.map(
                      (alergia, index) => {
                        return (
                          <Chip
                            key={`${alergia.nombre}${index}`}
                            label={alergia.nombre}
                          ></Chip>
                        );
                      }
                    )
                  : "No registradas"}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            {(userRol?.includes("Administrador") ||
              userRol?.includes("Enfermería") ||
              userRol?.includes("Nutriólogo")) && (
              <AgregarDetallesAdicionalesDialog
                id={datosPaciente?._id}
                additionalData={datosPaciente?.additionalData}
                setDataPaciente={setDataPaciente}
                datosPaciente={datosPaciente}
                overrideLoad
              />
            )}
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Censo setRefresh={setRefresh} idPaciente={datosPaciente?._id} />
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
};

export default InformacionAdicionalDialog;

import { Grid, List, Pagination, Typography } from "@mui/material";
import React from "react";
import { IoSearchSharp } from "react-icons/io5";

const ListaUsuarios = ({ data, ListComponent, setRefresh }) => {
  const [page, setPage] = React.useState(1);

  /*calculate the number of pages*/
  const numPages = Math.ceil(data.length / 10);

  React.useEffect(() => {
    setPage(1);
  }, [data]);

  return (
    <>
      <Grid item xs={12}>
        {/* number of results */}
        <Typography component="p" variant="body2" color="text.primary">
          {`Mostrando ${page > 1 ? page - 1 + "1" : page} - ${
            page * 10 > data.length ? data.length : page + "0"
          } de ${data.length} resultados`}
          {/* {`Mostrando 1 - 10 de ${pacientes.length} resultados`} */}
        </Typography>
      </Grid>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        sx={{ width: "100%" }}
      >
        {
          /* render the patients in the current page */
          data.slice((page - 1) * 10, page * 10).map((data, index) => (
            <ListComponent key={index} data={data} setRefresh={setRefresh} />
          ))
        }
      </List>
      {data.length === 0 && (
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ mb: 2 }}
        >
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            xs={12}
          >
            <IoSearchSharp
              style={{ width: "100px", height: "100px", textAlign: "center" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="p"
              variant="p"
              color="text.primary"
              textAlign={"center"}
            >
              No se encontraron resultados
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        item
        xs={12}
        md={12}
        justifyContent={"center"}
        alignContent={"center"}
      >
        <Pagination
          count={numPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </Grid>
    </>
  );
};

export default ListaUsuarios;

import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { BootstrapDialog, BootstrapDialogTitle } from "../BootstrapDialog";
import ResponseError from "../ResponseError";
import { RiFolderUserLine, RiSurveyLine } from "react-icons/ri";
import LabelDialogForm from "../LabelDialogForm";
import censoObj from "../../constants/censoObj";
import makeRequest from "../../services/makeRequest";

const Censo = ({ idPaciente, setRefresh }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState({
    ...censoObj,
    obersevacionesGenerales: "",
    pendientes: "",
    laboratorioEImagen: "",
  });
  const [errors, setErrors] = React.useState({});
  const [fetching, setFetching] = React.useState(false);
  const userRol = JSON.parse(localStorage.getItem("userData"))?.user?.rol;

  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = async () => {
    setFetching(true);
    try {
      const res = await makeRequest(
        "/api/v1/pacientes/obtenerCenso?id=" + idPaciente,
        "GET"
      );
      if (res === null) return setFetching(false);
      setData(res);
      setFetching(false);
    } catch (error) {
      setFetching(false);
      setError(error.message);
    }
  };

  const handleSave = async () => {
    setFetching(true);
    try {
      const res = await makeRequest("/api/v1/pacientes/editCenso", "PUT", {
        id: idPaciente,
        data: data,
      });
      setFetching(false);
      setOpen(false);
      setRefresh(true);
    } catch (error) {
      setFetching(false);
      setError(error.message);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const shouldEdit =
    userRol.includes("Administrador") || userRol.includes("Enfermería");
  return (
    <Box sx={{ width: "100%" }}>
      <Button
        fullWidth
        startIcon={<RiSurveyLine />}
        onClick={() => setOpen(true)}
        variant={"contained"}
      >
        Censo
      </Button>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Censo
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid container direction={"row"} spacing={2} sx={{ p: 2 }}>
            <Grid
              item
              container
              xs={5}
              sx={{
                overflowY: "scroll",
                height: "70vh",
                border: "solid 	#DCDCDC 1px",
                borderRadius: "5px",
              }}
            >
              {Object.keys(censoObj).map((key) => (
                <Grid
                  item
                  container
                  direction={"row"}
                  alignItems={"center"}
                  xs={12}
                  key={key}
                  spacing={1}
                >
                  <Grid item xs={3}>
                    <Checkbox
                      checked={data[key].value}
                      disabled={!shouldEdit}
                      onChange={(e) =>
                        setData({
                          ...data,
                          [key]: { ...data[key], value: e.target.checked },
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>{censoObj[key].title}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item container xs={7} spacing={2}>
              <Grid item xs={12}>
                <LabelDialogForm name={"Observaciones Generales"} />
                <TextField
                  value={data.obersevacionesGenerales}
                  onChange={(e) =>
                    setData({
                      ...data,
                      obersevacionesGenerales: e.target.value,
                    })
                  }
                  disabled={!shouldEdit}
                  fullWidth
                  multiline
                  variant="outlined"
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <LabelDialogForm name={"Pendientes"} />
                <TextField
                  value={data.pendientes}
                  onChange={(e) =>
                    setData({ ...data, pendientes: e.target.value })
                  }
                  fullWidth
                  multiline
                  disabled={!shouldEdit}
                  variant="outlined"
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <LabelDialogForm name={"Laboratorio e imagen"} />
                <TextField
                  value={data.laboratorioEImagen}
                  onChange={(e) =>
                    setData({ ...data, laboratorioEImagen: e.target.value })
                  }
                  fullWidth
                  disabled={!shouldEdit}
                  multiline
                  variant="outlined"
                  rows={4}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ResponseError error={error} />
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          {shouldEdit && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleSave()}
              disabled={fetching}
            >
              Guardar
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
};

export default Censo;

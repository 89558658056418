import {
  AccordionDetails,
  Button,
  ClickAwayListener,
  TextField,
} from "@mui/material";
import {
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React from "react";
import { FiFilter, FiSearch, FiTrash } from "react-icons/fi";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";

const FiltrosContainer = ({
  data,
  dataFilter,
  setDataFilter,
  filtros,
  FiltroPrincipal,
  fullWidth,
  allowCompleteFilter,
}) => {
  const [dataAux, setDataAux] = React.useState(data);
  const [values, setValues] = React.useState({ reset: false });
  const [open, setOpen] = React.useState(false);

  // filter
  React.useEffect(() => {
    if (data) {
      let filteredRows = data;
      Object.keys(values).forEach((key) => {
        if (values[key].value) {
          filteredRows = filteredRows.filter((row) => values[key].filter(row));
        }
      });
      setDataAux(filteredRows);
    }
  }, [values, data]);

  React.useEffect(() => {
    setDataFilter(dataAux);
  }, [dataAux, setDataFilter]);

  React.useEffect(() => {
    if (values.reset) {
      setValues({ ...values, reset: false });
    }
  }, [values.reset]);

  function getFilterSize() {
    if (FiltroPrincipal) {
      if (fullWidth) {
        return 6;
      } else return 4;
    } else return 12;
  }

  return (
    <>
      <Grid item md={getFilterSize()} xs={12}>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Accordion expanded={open} onChange={(e) => setOpen(!open)}>
            <AccordionSummary
              expandIcon={<MdOutlineArrowDropDownCircle />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <FiFilter />
              <Typography sx={{ ml: 2 }}>Filtros</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                zIndex: 1,
                position: "absolute",
                width: "100%",
                background: (theme) => theme.palette.background.paper,
                borderRadius: "0 0 10px 10px",
                boxShadow: "0px 8px 10px 0px rgba(0,0,0,0.30)",
              }}
            >
              <Grid container spacing={2}>
                {
                  // filtros
                  filtros.map((Filtro, index) => {
                    return (
                      <Grid item xs={12} key={index}>
                        <Filtro
                          values={values}
                          setValues={setValues}
                          allowCompleteFilter={allowCompleteFilter}
                        />
                      </Grid>
                    );
                  })
                }
                <Grid item xs={12}>
                  {/* reset button */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <Button
                      variant="outlined"
                      endIcon={<FiTrash />}
                      onClick={() => setValues({ reset: true })}
                    >
                      Limpiar filtros
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </ClickAwayListener>
      </Grid>
      {FiltroPrincipal && (
        <Grid item md={fullWidth ? 6 : 5} xs={12}>
          <FiltroPrincipal values={values} setValues={setValues} />
        </Grid>
      )}
    </>
  );
};

export default FiltrosContainer;

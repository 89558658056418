import { Button, CircularProgress, Grid } from "@mui/material";
import React from "react";
import { FiPlus } from "react-icons/fi";

import AgregarUsuarioDialog from "../../../components/AgregarDialogs/AgregarUsuarioDialog";
import EstatusFiltro from "../../../components/Filtros/EstatusFiltro";
import FiltrosContainer from "../../../components/Filtros/FiltrosContainer";
import NombrePersonaFiltro from "../../../components/Filtros/NombrePersonaFiltro";
import RolesFiltro from "../../../components/Filtros/RolesFiltro";
import UsernameFiltro from "../../../components/Filtros/UsernameFiltro";
import FichaUsuarios from "../../../components/ListaUsuarios/FichaUsuarios";
import ListaUsuarios from "../../../components/ListaUsuarios/ListaUsuarios";

const url = process.env.REACT_APP_URL_BACK;

const Usuarios = () => {
  const [open, setOpen] = React.useState(false);
  const [pacientes, setPacientes] = React.useState(null);
  const [pacientesFilter, setPacientesFilter] = React.useState(null);
  const [pacientesAux, setPacientesAux] = React.useState(null);
  const [nombre, setNombre] = React.useState("");
  const [refresh, setRefresh] = React.useState(false);
  React.useEffect(() => {
    if (refresh) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = () => {
    // fetch data
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+"/api/v1/usuarios/listar", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // convert idXXXXXX property to id
        // console.log(data);
        data.forEach((row) => {
          Object.keys(row).forEach((key) => {
            if (key.includes("_id")) {
              row["id"] = row[key];
            }
          });
        });

        let datos = data.filter((dato) => !dato.username.includes("Wundertec"));
        setPacientes(datos);
        setPacientesFilter(datos);
        setPacientesAux(datos);
      })
      .catch((e) => {
        console.log(e);
        setPacientes(null);
        setPacientesFilter(null);
        setPacientesAux(null);
      });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (pacientes) {
      // filter by name
      if (nombre) {
        setPacientes(
          pacientes.filter((paciente) =>
            `${paciente.nombre} ${paciente.apellidoPaterno} ${paciente.apellidoPaterno}`
              .toLowerCase()
              .includes(nombre.toLowerCase())
          )
        );
      } else {
        setPacientes(pacientesAux);
      }
    }
  }, [nombre]);

  return (
    <Grid container spacing={4}>
      {/* filters */}
      {pacientes && (
        <FiltrosContainer
          data={pacientes}
          dataFilter={pacientesFilter}
          setDataFilter={setPacientesFilter}
          filtros={[UsernameFiltro, RolesFiltro, EstatusFiltro]}
          FiltroPrincipal={NombrePersonaFiltro}
        />
      )}
      <Grid item md={3} xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setOpen(true)}
          fullWidth
          startIcon={<FiPlus />}
        >
          Agregar nuevo
        </Button>
      </Grid>

      {/* list */}
      <Grid item xs={12}>
        {pacientesFilter && (
          <ListaUsuarios
            data={pacientesFilter}
            ListComponent={FichaUsuarios}
            setRefresh={setRefresh}
          />
        )}
      </Grid>
      {/* show skeleton */}
      {!pacientes && (
        <Grid
          item
          xs={12}
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      )}
      <AgregarUsuarioDialog
        open={open}
        setOpen={setOpen}
        setRefresh={setRefresh}
      />
    </Grid>
  );
};

export default Usuarios;

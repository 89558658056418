import {
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import RecuperaContrasenaDialog from "../components/RecuperaContrasenaDialog";
import ResponseError from "../components/ResponseError";
import login from "../services/login";
const styles = {
  container: {
    background: (theme) =>
      "url(../assets/img/login.png) center center" +
      theme.palette.primary.main +
      "80",
    backgroundSize: "cover",
    height: "100vh",
    width: "100vw",
  },
  center: {
    textAlign: "center",
  },
  vertical: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
    width: "100%",
  },
  entrar: {
    backgroundColor: (theme) => theme.palette.secondary.main,
    border: (theme) => "1px solid " + theme.palette.secondary.main,
    borderRadius: "15px",
    padding: "10px 40px",
    fontSize: "12px",
    color: "white",
    mt: 2,
    "&:hover": {
      backgroundColor: (theme) => theme.palette.secondary.main,
      borderColor: "#0062cc",
      boxShadow: "none",
    },
  },
  control: {
    fontSize: "12px",
    marginTop: "15px",
    borderRadius: "3rem",
    color: "#9c9c9c",
    backgroundColor: "#FFF",
    "& .Mui-focused": {
      borderRadius: "3rem",
    },
    "& :hover": {
      borderRadius: "3rem",
    },
    // padding: '10px 20px',
  },
};

const Login = () => {
  const [open, setOpen] = React.useState(false);
  const handleShow = () => setOpen(true);
  const [values, setValues] = React.useState({
    showPassword: false,
    username: "",
    password: "",
  });
  const [error, setError] = React.useState(null);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value.trim() });
  };

  const handleSubmitLogin = () => {
    setError(null);
    login(values, setError);
  };

  return (
    <Grid
      container
      direction={"row"}
      justifyContent="center"
      alignItems={"center"}
      className="bgLogin"
      sx={styles.container}
    >
      <Grid
        container
        justifyContent="center"
        alignItems={"center"}
        item
        lg={4}
        xs={12}
        sm={6}
        direction="column"
      >
        <FormControl
          sx={{ width: "100%" }}
          style={{
            background: "rgba(0, 0, 0, 0.5)",
            padding: "50px 30px 60px",
          }}
        >
          <Grid item sx={styles.center}>
            <img
              style={{ margin: "20px auto", width: "225px" }}
              src="../assets/img/logo-blanco.png"
              alt=""
            />
          </Grid>
          <ResponseError error={error} />
          <TextField
            autoComplete="off"
            type="text"
            className="form-control form-control-login"
            sx={styles.control}
            value={values.username}
            onChange={handleChange("username")}
            id="exampleInputEmail1"
            placeholder="Nombre de usuario"
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleSubmitLogin();
              }
            }}
          />

          <TextField
            autoComplete="new-password"
            type={values.showPassword ? "text" : "password"}
            className="form-control form-control-login"
            onChange={handleChange("password")}
            value={values.password}
            sx={styles.control}
            id="exampleInputPassword1"
            placeholder="Contraseña"
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    setValues({
                      ...values,
                      showPassword: !values.showPassword,
                    })
                  }
                  sx={{ mr: 0.5 }}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {values.showPassword ? <FiEyeOff /> : <FiEye />}
                </IconButton>
              ),
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSubmitLogin();
              }
            }}
          />

          <Grid item style={styles.center}>
            <Button
              // href="/catalogoPaciente"
              onClick={handleSubmitLogin}
              sx={styles.entrar}
              type="submit"
              className="btn btn-primary entrar pt-20"
            >
              Acceder
            </Button>
          </Grid>
        </FormControl>
        <RecuperaContrasenaDialog open={open} setOpen={setOpen} />
      </Grid>
    </Grid>

);
};

export default Login;

import { Box, Chip, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import LabelDialogForm from "../LabelDialogForm";
import censoObj from "../../constants/censoObj";
const CardCenso = ({ paciente }) => {
  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        borderBottom: "1px solid #ccc",
        maxHeight: "120px",
      }}
      spacing={1}
    >
      <Grid item container alignContent={"flex-start"} xs={3}>
        <Grid item xs={12}>
          <Typography variant="h6" color="primary">
            {paciente.cama}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body" color="text.secondary">
            {`${paciente.nombre} ${paciente.apellidoPaterno} ${paciente.apellidoMaterno}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={3}
        sx={{
          overflowY: "auto",
          maxHeight: "100%",
          marginTop: "1px",

          "&::-webkit-scrollbar": {
            width: "3px",
          },
        }}
        spacing={1}
      >
        {Object.keys(censoObj).map((key) => {
          if (paciente?.censo == undefined || !paciente?.censo[key]?.value)
            return <></>;

          return (
            <Grid key={key} item xs={"auto"}>
              <Chip
                color="secondary"
                size="small"
                label={censoObj[key].title}
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid item container direction={"row"} xs={6} spacing={1}>
        <Grid item xs={4}>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="primary"
              sx={{
                marginTop: "-10px",
              }}
            >
              {`Observaciones:`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                border: "1px solid #ccc",
                height: "80px",
                overflowY: "auto",
                borderRadius: "5px",
                marginBottom: "10px",
                "&::-webkit-scrollbar": {
                  width: "3px",
                },
              }}
            >
              {`${paciente?.censo?.obersevacionesGenerales || ""}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="primary"
              sx={{
                marginTop: "-10px",
              }}
            >
              {`Pendientes:`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                border: "1px solid #ccc",
                height: "80px",
                overflowY: "auto",
                borderRadius: "5px",
                marginBottom: "10px",
                "&::-webkit-scrollbar": {
                  width: "3px",
                },
              }}
            >
              {`${paciente?.censo?.pendientes || ""}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="primary"
              sx={{
                marginTop: "-10px",
              }}
            >
              {`Laboratorio e Imagen:`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                border: "1px solid #ccc",
                height: "80px",
                overflowY: "auto",
                borderRadius: "5px",
                marginBottom: "10px",
                "&::-webkit-scrollbar": {
                  width: "3px",
                },
              }}
            >
              {`${paciente?.censo?.laboratorioEImagen || ""}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardCenso;

import React from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  Slide,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BiX } from "react-icons/bi";

const BootstrapDialogStyled = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = (props) => {
  const { children, onClose, ...other } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <BootstrapDialogStyled
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      {...other}
    >
      {children}
    </BootstrapDialogStyled>
  );
};

const BootstrapDialogLg = (props) => {
  const { children, onClose, ...other } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <BootstrapDialogStyled
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      {...other}
    >
      {children}
    </BootstrapDialogStyled>
  );
};

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        mb: 2,
        p: 2,
        backgroundColor: (theme) => theme.palette.secondary.main,
        color: (theme) => theme.palette.secondary.contrastText,
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <BiX />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export { BootstrapDialog, BootstrapDialogLg, BootstrapDialogTitle };

import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Chip,
  Tooltip,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { BiCheck, BiX } from "react-icons/bi";

import { Box } from "@mui/system";
import CAMAS from "../../constants/camas.json";
import { VscCommentDiscussion } from "react-icons/vsc";
import FinalizarAseoDialog from "./FinalizarAseoDialog";
import AsignarHabitacionDialog from "./AsignarHabitacionDialog";
import AgregarComentarioDialog from "./AgregarComentarioDialog";
import { BootstrapDialog, BootstrapDialogTitle } from "../BootstrapDialog";

const url = process.env.REACT_APP_URL_BACK;

const ChipEstatus = (estatus) => {
  return estatus ? (
    <Tooltip title={"En proceso"}>
      <Chip
        icon={<BiCheck />}
        label="En proceso"
        color="success"
        variant="outlined"
      />
    </Tooltip>
  ) : (
    <Tooltip title={"Sin atender"}>
      <Chip
        icon={<BiX />}
        label="Sin atender"
        color="error"
        variant="outlined"
      />
    </Tooltip>
  );
};
const HabitacionCard = ({ habitacion }) => {
  const [comentariosList, setComentariosList] = React.useState([]);
  const [comentariosListDialog, setComentariosListDialog] =
    React.useState(false);

  const [responseError, setResponseError] = React.useState(null);

  function mostrarListaComentarios() {
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    fetch(url+"/api/v1/limpieza/historial/" + habitacion.id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setComentariosList(res);
        setComentariosListDialog(true);
      })
      .catch((err) => {
        console.log(err);
        setResponseError(err.message);
      });
  }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Habitación {habitacion.cama}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {CAMAS[habitacion?.cama] &&
            `Ubicación: ${CAMAS[habitacion?.cama]["Areas"]} ${
              CAMAS[habitacion?.cama]["piso/area"]
            }`}
        </Typography>
        <Typography variant="h5" component="div"></Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Tipo de aseo: {habitacion.tipo}
        </Typography>

        {ChipEstatus(habitacion.estado)}

        {!habitacion.estado && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">Colaborador sin asignar</Typography>
            <Typography variant="body2"></Typography>
          </Box>
        )}
        {habitacion.estado && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">
              Colaborador asignado: {habitacion.limpieza}
            </Typography>
          </Box>
        )}
      </CardContent>
      <CardActions>
        {!habitacion.estado && (
          <Grid container>
            <AsignarHabitacionDialog id={habitacion.id} />
          </Grid>
        )}
        {habitacion.estado && (
          <Grid container>
            <Grid item xs={4}>
              <AgregarComentarioDialog
                data={habitacion}
                mostrarListaComentarios={mostrarListaComentarios}
              />
              <Tooltip title="Ver comentarios">
                <IconButton
                  onClick={() => {
                    mostrarListaComentarios();
                  }}
                >
                  <VscCommentDiscussion />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item container xs={8} justifyContent={"flex-end"}>
              <FinalizarAseoDialog data={habitacion} />
            </Grid>
          </Grid>
        )}
      </CardActions>

      {/* Lista de comentarios dialog */}
      <BootstrapDialog
        open={comentariosListDialog}
        onClose={() => setComentariosListDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <BootstrapDialogTitle
          onClose={() => setComentariosListDialog(false)}
          id="form-dialog-title"
        >
          Comentarios de aseo
        </BootstrapDialogTitle>
        <DialogContent>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Fecha</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Descripción</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Comentarios</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {comentariosList.map((row) => (
                  <TableRow
                    key={row.fecha}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {new Date(row.fecha).toLocaleString()}
                    </TableCell>
                    <TableCell>{row.descripcion}</TableCell>
                    <TableCell
                      sx={{
                        wordWrap: "break-word",
                        whiteSpace: "break-spaces",
                        maxWidth: "300px",
                      }}
                    >
                      {row.comentarios}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setComentariosListDialog(false)}
            color="primary"
          >
            Cerrar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Card>
  );
};

export default HabitacionCard;

import { Paper, Typography } from "@mui/material";
const LabelDialogForm = ({ name }) => {
  const styles = {
    labels: {
      color: "black",
      fontSize: "12px",
    },
    labelPaper: {
      backgroundColor: (theme) => theme.palette.background.paper,
      marginBottom: "0.5rem",
      width: "100%",
      padding: "5px 10px",
      border: "none",
    },
  };
  return (
    <Paper sx={styles.labelPaper} variant="outlined">
      <Typography variant="h6" sx={styles.labels}>
        {name}
      </Typography>
    </Paper>
  );
};

export default LabelDialogForm;

import { TextField } from "@mui/material";
import React from "react";
import { MdClose } from "react-icons/md";

const NoCuentaFiltro = ({ values, setValues }) => {
  const [noCuenta, setNoCuenta] = React.useState("");
  React.useEffect(() => {
    setValues({
      ...values,
      noCuenta: {
        value: noCuenta,
        filter: (row) => {
          return row.noCuenta.toLowerCase().includes(noCuenta.toLowerCase());
        },
      },
    });
  }, [noCuenta]);

  React.useEffect(() => {
    if (values.reset) {
      setNoCuenta("");
    }
  }, [values.reset]);

  return (
    <TextField
      id="outlined-basic"
      label="Buscar por No. de cuenta"
      variant="standard"
      fullWidth
      InputProps={{
        endAdornment: (
          <MdClose
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => setNoCuenta("")}
          />
        ),
      }}
      name="noCuenta"
      value={noCuenta}
      onChange={(e) => setNoCuenta(e.target.value)}
    />
  );
};

export default NoCuentaFiltro;

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React from "react";
import { MdOutlineCheck } from "react-icons/md";
import verifyToken from "../../services/verifyToken";
import { BootstrapDialog, BootstrapDialogTitle } from "../BootstrapDialog";
import ResponseError from "../ResponseError";

const url = process.env.REACT_APP_URL_BACK;

const FinalizarAseoDialog = ({ data }) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleSubmit = (event) => {
    verifyToken();
    event.preventDefault();
    const userData = JSON.parse(localStorage.getItem("userData")) || {};

    //send request
    fetch(url+"/api/v1/limpieza/finalizarAseo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (userData?.user?.token || ""),
      },
      body: JSON.stringify({
        idPaciente: data.id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setError(data.error);
        } else {
          setOpenDialog(false);
        }
      });
  };
  return (
    <>
      <Button
        size="small"
        onClick={() => setOpenDialog(true)}
        endIcon={<MdOutlineCheck />}
      >
        Finalizar Aseo
      </Button>
      <ResponseError error={error} />
      <BootstrapDialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-labelledby="customized-dialog-title"
      >
        <BootstrapDialogTitle id="customized-dialog-title">
          {"¿Finalizar aseo?"}
        </BootstrapDialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "black" }}>
            Estas apunto de finalizar el aseo de la habitación{" "}
            <i>{data?.cama ? data?.cama : "N/A"}</i>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleSubmit}
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default FinalizarAseoDialog;

export default async function makeRequest(url, method = "GET", body = null) {

  const uri = process.env.REACT_APP_URL_BACK;

  const token = JSON.parse(localStorage.getItem("userData"))?.user?.token || {};
  const config = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (token || ""),
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  const response = await fetch(uri+url, config);
  const data = await response.json();

  return data;
}

import { Chip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import rolArray from "../../constants/rolArray";

const RolesFiltro = ({ values, setValues }) => {
  const [roles, setRoles] = React.useState({});

  React.useEffect(() => {
    setValues({
      ...values,
      roles: {
        value: roles,
        filter: (row) => {
          /* filter with multiple roles */
          let filter = false;
          Object.keys(roles).map((rol) => {
            if (roles[rol]) {
              filter = filter || row.roles.includes(rol);
            }
          });

          /* if every rol is false, then flag is true */
          if (Object.values(roles).every((rol) => !rol)) {
            filter = true;
          }
          return filter;
        },
      },
    });
  }, [roles]);

  React.useEffect(() => {
    if (values.reset) {
      setRoles({});
    }
  }, [values.reset]);

  return (
    <Box>
      {" "}
      <Typography
        component="p"
        variant="body2"
        color="text.primary"
        gutterBottom
      >
        Roles
      </Typography>
      {rolArray.map((rol, index) => (
        <Chip
          key={index}
          label={rol}
          variant={roles[rol] ? "default" : "outlined"}
          sx={{ mr: 1, mb: 1 }}
          color="primary"
          onClick={() => {
            setRoles({
              ...roles,
              [rol]: !roles[rol],
            });
          }}
        />
      ))}
    </Box>
  );
};

export default RolesFiltro;

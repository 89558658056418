import { TextField } from "@mui/material";
import React from "react";

const UsernameFiltro = ({ values, setValues }) => {
  const [username, setUsername] = React.useState("");

  React.useEffect(() => {
    setValues({
      ...values,
      username: {
        value: username,
        filter: (row) =>
          row.username.toLowerCase().includes(username.toLowerCase()),
      },
    });
  }, [username]);

  React.useEffect(() => {
    if (values.reset) {
      setUsername("");
    }
  }, [values.reset]);

  return (
    <TextField
      id="outlined-basic"
      label="Nombre de usuario"
      variant="standard"
      fullWidth
      name="username"
      value={username}
      onChange={(e) => setUsername(e.target.value)}
    />
  );
};

export default UsernameFiltro;

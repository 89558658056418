export default function login(values, setError) {

  const url = process.env.REACT_APP_URL_BACK;
  fetch(url+"/api/v1/usuarios/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.errors) {
        setError("Usuario o contraseña incorrectos");
      } else {
        localStorage.setItem("userData", JSON.stringify(data));
        window.location.href = "/dashboard";
      }
    })
    .catch((e) => {
      console.log(e);
      setError("Datos erroneos.");
    });
}

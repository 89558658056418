import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import socketIOClient from "socket.io-client";
import TRACKER from "../constants/trackerV2.json";
import AgregarDetallesAdicionalesDialog from "./AccionesTracker/AgregarDetallesAdicionalesDialog";
import DeshabilitarHabitacion from "./AccionesTracker/DeshabilitarHabitacion";
import RealizarInspeccion from "./AccionesTracker/RealizarInspeccion";
import TiempoAproximado from "./AccionesTracker/AddSubProceso";

const roles = {
  "Médico de enlace": ["probableAlta"],
  Enfermería: ["altaEnfermeria", "paseDeSalidaEnfermeria"],
  "Análisis de Cuenta": ["analisisDeCuenta", "ajusteCuenta"],
  Seguros: ["seguros", "aseguradora", "detallesCobro", "validacionSeguros"],
  Aseguradora: ["aseguradora"],
  "Atención Integral": ["cobro", "documentosCobro", "detallesCobro"],
  Hotelería: ["habitacionAseada"],
  "Relaciones Públicas": ["checklist", "habitacionLista"],
  Mantenimiento: ["mantenimiento"],
  Médico: ["probableAlta", "altaMedica"],
  Superdoctor: ["probableAlta", "altaMedica"],
  "Almacén general": ["FDL"],
  Traslado: ["paseDeSalidaTraslado", "traslado"],
};

const AccionesTracker = ({
  diagrama,
  actividadSeleccionada,
  setActividadSeleccionada,
  setRefreshActividadesSwitch,
  idPaciente,
  datosPaciente,
  cama,
}) => {
  const [comentarios, setComentarios] = React.useState("");
  const [switchStates, setSwitchStates] = React.useState([]);
  const [selectState, setSelectState] = React.useState("");
  const [auxiliarState, setAuxiliarState] = React.useState([]);
  const [botonIsEnabled, setBotonIsEnabled] = React.useState(false);
  const [opciones, setOpciones] = React.useState({});
  const [altaM, setAltaM] = React.useState({});

  const detallesPorActividad = {
    altaEnfermeria: {
      comentarios: true,
      switch: [
        { title: "Confirma alta en expediente clínico", state: false },
        { title: "Devolución de insumos no utilizados", state: false },
      ],
    },
    FDL: {
      comentarios: true,
      switch: [
        { title: "Cargados y liberados", state: false },
        { title: "Remisiones adjuntadas", state: false },
      ],
    },

    analisisDeCuenta: {
      comentarios: true,
      switch: [{ title: "Registro de pre-alta en assist", state: false }],
    },
    seguros: {
      comentarios: true,
      switch: [
        { title: "Estado de cuenta", state: false },
        { title: "Remisiones", state: false },
        { title: "Carta de autorización / formatos aseguradora", state: false },
        { title: "Nota quirúrgica / nota de evolución", state: false },
      ],
    },

    validacionSeguros: {
      comentarios: true,
      switch:
        diagrama?.aseguradora?.aseguradoraResponse === 1
          ? [
              { title: "Carta de autorización", state: false },
              { title: "Suma asegurada ", state: false },
              {
                title: "Tabulación de honorarios médicos completa",
                state: false,
              },
              { title: "Gastos no cubiertos", state: false },
            ]
          : [
              { title: "Notificación a análisis", state: false },
              { title: "Notificación a médico", state: false },
            ],
    },

    documentosCobro: {
      comentarios: true,
      switch: [
        { title: "Pagare firmado", state: false },
        { title: "Caratula de ingreso con identificaciones", state: false },
      ],
    },
    paseDeSalidaEnfermeria: {
      comentarios: true,
      switch: [
        { title: "Pase de salida recibido", state: false },
        { title: "Llamado a traslado", state: false },
      ],
    },
    paseDeSalidaTraslado: {
      comentarios: true,
      switch: [{ title: "Pase de salida recibido", state: false }],
    },

    traslado: {
      comentarios: true,
      switch: [{ title: "La habitacion esta liberada", state: false }],
    },
  };

  const handleBotonEnabled = () => {
    if (diagrama.mantenimiento.estado === 3) {
      setBotonIsEnabled(false);
    } else if (!detallesPorActividad[actividadSeleccionada]) {
      setBotonIsEnabled(true);
    } else if (detallesPorActividad[actividadSeleccionada].noSwitch) {
      if (detallesPorActividad[actividadSeleccionada].select && selectState) {
        if (detallesPorActividad[actividadSeleccionada].selectRejected) {
          if (selectState === 2) {
            if (auxiliarState.length > 0) {
              if (auxiliarState.includes("Otros")) {
                if (comentarios.length > 0) {
                  setBotonIsEnabled(true);
                } else {
                  setBotonIsEnabled(false);
                }
              } else {
                setBotonIsEnabled(true);
              }
            }
          } else {
            setBotonIsEnabled(true);
          }
        } else setBotonIsEnabled(true);
      } else {
        setBotonIsEnabled(false);
      }
    } else if (detallesPorActividad[actividadSeleccionada].switch) {
      if (detallesPorActividad[actividadSeleccionada].select) {
        if (selectState && switchStates.every((element) => element === true)) {
          setBotonIsEnabled(true);
        } else {
          setBotonIsEnabled(false);
        }
      } else {
        if (
          switchStates.length > 0 &&
          switchStates.every((element2) => element2 === true)
        ) {
          setBotonIsEnabled(true);
        } else {
          setBotonIsEnabled(false);
        }
      }
    } else {
      setBotonIsEnabled(false);
    }
  };

  React.useEffect(() => {
    if (detallesPorActividad[actividadSeleccionada]?.switch) {
      setSwitchStates(
        Array(detallesPorActividad[actividadSeleccionada].switch.length).fill(
          false
        )
      );
    } else {
      setSwitchStates([]);
    }
  }, [actividadSeleccionada]);

  React.useEffect(() => {
    handleBotonEnabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    switchStates,
    selectState,
    comentarios,
    auxiliarState,
    diagrama.mantenimiento,
  ]);

  const updateHistorial = (
    idActividad,
    estado,
    comentariosArg,
    descripcion
  ) => {
    const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
      transports: ["websocket"],
    });
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    const idUsuario = userData.user._id;
    opciones.tipoAlta = opciones.tipoAlta ? "Defunción" : "";
    altaM.tipo = altaM.tipo ? 1 : 0;
    socket.emit("registrarTracker", {
      idActividad,
      estado,
      idPaciente,
      comentarios: comentariosArg.trim(),
      descripcion,
      idUsuario,
      opciones,
      altaM,
    });
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAuxiliarState(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const rol = JSON.parse(localStorage.getItem("userData") || {}).user?.rol;
  console.log(rol);

  const componenteActividad = (
    <Paper sx={{ p: 1 }}>
      <Typography gutterBottom color="primary" variant="h3" component={"div"}>
        Acciones para {TRACKER[actividadSeleccionada].title}
      </Typography>
      <Divider />
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} container>
          {detallesPorActividad[actividadSeleccionada] &&
            detallesPorActividad[actividadSeleccionada]?.switch?.map(
              (switchActividad, index) => {
                return (
                  <Grid
                    container
                    sx={{ p: 1 }}
                    key={index + switchActividad.title}
                  >
                    <Grid item xs={10}>
                      <Typography variant="p">
                        {switchActividad.title}
                      </Typography>
                    </Grid>
                    <Grid item container justifyContent="flex-end" xs={2}>
                      <Switch
                        checked={switchStates[index] || false}
                        onChange={() => {
                          setSwitchStates(
                            switchStates.map((state, i) => {
                              if (i === index) {
                                return !state;
                              }
                              return state;
                            })
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              }
            )}
          {detallesPorActividad[actividadSeleccionada]?.select && (
            <>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="p"
                  component="div"
                  sx={{ textAlign: "center" }}
                >
                  {detallesPorActividad[actividadSeleccionada].select.title}:
                </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-end"
                alignContent="center"
                xs={12}
              >
                <Select
                  value={selectState || ""}
                  onChange={(e) => {
                    setSelectState(e.target.value);
                  }}
                  displayEmpty
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  <MenuItem value="" disabled>
                    No hay selección
                  </MenuItem>
                  {detallesPorActividad[
                    actividadSeleccionada
                  ].select.options.map((option, index) => {
                    return (
                      <MenuItem key={option} value={index + 1}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              {selectState === 2 && actividadSeleccionada === "aseguradora" && (
                <>
                  <Grid item xs={6}>
                    <Typography variant="h6" sx={{ fontSize: "1rem", mb: 2 }}>
                      {
                        detallesPorActividad[actividadSeleccionada]
                          .selectRejected.title
                      }
                    </Typography>
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={6}>
                    <Select
                      value={auxiliarState}
                      onChange={handleChange}
                      multiple
                      sx={{
                        mt: 2,
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                      input={<OutlinedInput id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      <MenuItem value="" disabled>
                        No hay selección
                      </MenuItem>
                      {detallesPorActividad[
                        actividadSeleccionada
                      ].selectRejected.options.map((option, _index) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </>
              )}
            </>
          )}
          {actividadSeleccionada === "altaMedica" && (
            <Grid
              container
              alignItems="center"
              justifyContent={"center"}
              sx={{ p: 1 }}
            >
              <Grid item xs={6}>
                <Typography variant="p" sx={{ mb: 2 }}>
                  ¿El alta es por defunción?
                </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems={"center"}
                xs={6}
              >
                <Typography variant="p" sx={{ mr: 1 }}>
                  No
                </Typography>
                <Switch
                  checked={opciones.tipoAlta || false}
                  onChange={() => {
                    setOpciones({
                      ...opciones,
                      tipoAlta: !opciones.tipoAlta,
                    });
                  }}
                />
                <Typography variant="p" sx={{ ml: 1 }}>
                  Si
                </Typography>
              </Grid>
            </Grid>
          )}
          {actividadSeleccionada === "probableAlta" &&
            (rol.includes("Médico") || rol.includes("Administrador")) && (
              <Grid
                container
                alignItems="center"
                justifyContent={"center"}
                sx={{ p: 1 }}
              >
                <Grid item xs={6}>
                  <Typography variant="p" sx={{ mb: 2 }}>
                    Acceso - Alta médica
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  alignItems={"center"}
                  xs={6}
                >
                  <Typography variant="p" sx={{ mr: 1 }}>
                    No
                  </Typography>
                  <Switch
                    checked={altaM.tipo || false}
                    onChange={() => {
                      setAltaM({
                        ...altaM,
                        tipo: !altaM.tipo,
                      });
                    }}
                  />
                  <Typography variant="p" sx={{ ml: 1 }}>
                    Si
                  </Typography>
                </Grid>
              </Grid>
            )}
          {actividadSeleccionada === "probableAlta" && altaM.tipo && (
            <Grid
              container
              alignItems="center"
              justifyContent={"center"}
              sx={{ p: 1 }}
            >
              <Grid item xs={6}>
                <Typography variant="p" sx={{ mb: 2 }}>
                  ¿El alta es por defunción?
                </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems={"center"}
                xs={6}
              >
                <Typography variant="p" sx={{ mr: 1 }}>
                  No
                </Typography>
                <Switch
                  checked={opciones.tipoAlta || false}
                  onChange={() => {
                    setOpciones({
                      ...opciones,
                      tipoAlta: !opciones.tipoAlta,
                    });
                  }}
                />
                <Typography variant="p" sx={{ ml: 1 }}>
                  Si
                </Typography>
              </Grid>
            </Grid>
          )}
          {actividadSeleccionada === "altaEnfermeria" && (
            <>
              <AgregarDetallesAdicionalesDialog
                id={datosPaciente?._id}
                additionalData={datosPaciente?.additionalData || {}}
              />
              <Grid
                container
                alignItems="center"
                justifyContent={"center"}
                sx={{ p: 1 }}
              >
                <Grid item xs={6}>
                  <Typography variant="p" sx={{ mb: 2 }}>
                    ¿Aplica para FDL?
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  alignItems={"center"}
                  xs={6}
                >
                  <Typography variant="p" sx={{ mr: 1 }}>
                    Si
                  </Typography>
                  <Switch
                    checked={opciones.saltoFDL || false}
                    onChange={() => {
                      setOpciones({
                        ...opciones,
                        saltoFDL: !opciones.saltoFDL,
                      });
                    }}
                  />
                  <Typography variant="p" sx={{ ml: 1 }}>
                    No
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          {actividadSeleccionada === "seguros" && (
            <Grid
              container
              alignItems="center"
              justifyContent={"center"}
              sx={{ p: 1 }}
            >
              <Divider sx={{ width: "100%", mb: 1 }} />
              <Grid item xs={6}>
                <Typography variant="p" sx={{ mb: 2 }}>
                  ¿Egreso ágil?
                </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems={"center"}
                xs={6}
              >
                <Typography variant="p" sx={{ mr: 1 }}>
                  No
                </Typography>
                <Switch
                  checked={opciones.agil || false}
                  onChange={() => {
                    setOpciones({
                      ...opciones,
                      agil: !opciones.agil,
                    });
                  }}
                />
                <Typography variant="p" sx={{ ml: 1 }}>
                  Si
                </Typography>
              </Grid>
            </Grid>
          )}
          {actividadSeleccionada === "checklist" && (
            <Grid
              container
              alignItems="center"
              justifyContent={"center"}
              sx={{ p: 1 }}
            >
              <Grid item xs={6}>
                <Typography variant="p" sx={{ mb: 2 }}>
                  ¿Solicitar apoyo de mantenimiento?
                </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems={"center"}
                xs={6}
              >
                <Typography variant="p" sx={{ mr: 1 }}>
                  No
                </Typography>
                <Switch
                  checked={opciones.mantenimiento || false}
                  onChange={() => {
                    setOpciones({
                      ...opciones,
                      mantenimiento: !opciones.mantenimiento,
                    });
                  }}
                />
                <Typography variant="p" sx={{ ml: 1 }}>
                  Si
                </Typography>
              </Grid>
            </Grid>
          )}
          {actividadSeleccionada === "mantenimiento" &&
            diagrama.mantenimiento.estado === 0 && (
              <RealizarInspeccion cama={cama} idPaciente={idPaciente} />
            )}
          {actividadSeleccionada === "mantenimiento" &&
            diagrama.mantenimiento.estado !== 0 && (
              <DeshabilitarHabitacion
                cama={cama}
                idPaciente={idPaciente}
                diagrama={diagrama}
              />
            )}
          {actividadSeleccionada === "mantenimiento" && (
            <TiempoAproximado idPaciente={idPaciente} />
          )}
          {actividadSeleccionada === "mantenimiento" &&
            diagrama.mantenimiento.tiempo?.duracion && (
              <Box Box sx={{ width: "100%" }}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent={"center"}
                  item
                  sx={{ p: 1 }}
                >
                  <Typography
                    gutterBottom
                    color="primary"
                    variant="h3"
                    component={"div"}
                  >
                    Tiempo Estimado {diagrama.mantenimiento.tiempo.horas}:
                    {diagrama.mantenimiento.tiempo.minutos} hrs
                  </Typography>
                </Grid>
              </Box>
            )}

          {actividadSeleccionada === "aseguradora" && (
            <>
              <Grid item xs={12}>
                <Typography variant="p" textAlign={"center"}>
                  Respuesta de la aseguradora
                </Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={12}>
                <Select
                  value={opciones.aseguradoraResponse || 1}
                  onChange={(e) => {
                    setOpciones({
                      ...opciones,
                      aseguradoraResponse: e.target.value,
                    });
                  }}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  <MenuItem value={1}>{"Aceptación"}</MenuItem>
                  <MenuItem value={2}>{"Revaloración"}</MenuItem>
                  <MenuItem value={3}>{"Ajuste de Cuenta"}</MenuItem>
                  <MenuItem value={4}>{"Rechazo definitivo"}</MenuItem>
                </Select>
              </Grid>
            </>
          )}
          {actividadSeleccionada === "cobro" && (
            <>
              <Grid
                container
                alignItems="center"
                justifyContent={"center"}
                sx={{ p: 1 }}
              >
                <Grid item xs={6}>
                  <Typography variant="p" sx={{ mb: 2 }}>
                    ¿Hace el pago el paciente?
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  alignItems={"center"}
                  xs={6}
                >
                  <Typography variant="p" sx={{ mr: 1 }}>
                    No
                  </Typography>
                  <Switch
                    checked={opciones.pagoPaciente || false}
                    onChange={() => {
                      setOpciones({
                        ...opciones,
                        pagoPaciente: !opciones.pagoPaciente,
                      });
                    }}
                  />
                  <Typography variant="p" sx={{ ml: 1 }}>
                    Si
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="p" textAlign={"center"}>
                  Pago
                </Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={12}>
                <Select
                  value={opciones.cobroResponse || 1}
                  onChange={(e) => {
                    setOpciones({
                      ...opciones,
                      cobroResponse: e.target.value,
                    });
                  }}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  <MenuItem value={1}>{"Completo"}</MenuItem>
                  <MenuItem value={2}>{"Incompleto"}</MenuItem>
                  <MenuItem value={3}>{"Médico Paga"}</MenuItem>
                </Select>
              </Grid>
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="p"
            sx={{ fontSize: "1rem", mt: 2 }}
            component={"div"}
          >
            Comentarios
          </Typography>
          <TextField
            placeholder={
              auxiliarState.includes("Otros") ? "Obligatorio" : "Opcional"
            }
            multiline
            fullWidth
            rows={3}
            value={comentarios}
            onChange={(e) => setComentarios(e.target.value)}
          ></TextField>
        </Grid>
      </Grid>

      <Button
        variant="contained"
        sx={{ mt: 2, mb: 2 }}
        color="secondary"
        fullWidth
        disabled={!botonIsEnabled || false}
        onClick={() => {
          setActividadSeleccionada(null);
          let descripcion = `${diagrama[actividadSeleccionada].title} ha sido aprobado.`;
          if (detallesPorActividad[actividadSeleccionada]?.select) {
            if (selectState === 1) {
              descripcion =
                detallesPorActividad[actividadSeleccionada].select.approved;
            } else {
              descripcion =
                detallesPorActividad[actividadSeleccionada].select.rejected;
            }
          }
          if (actividadSeleccionada === "aseguradora") {
            let estado = 1;
            if (
              opciones.aseguradoraResponse === 2 ||
              opciones.aseguradoraResponse === 3
            ) {
              estado = 2;
            }
            const motivosArray = [
              "Se aceptó la cuenta",
              "Se solicitó revaloración de cuenta",
              "Se solicitó ajuste de cuenta",
              "Se rechazó definitivamente la cuenta",
            ];
            updateHistorial(
              actividadSeleccionada,
              estado,
              comentarios || "",
              motivosArray[(opciones.aseguradoraResponse || 1) - 1]
            );
          } else if (actividadSeleccionada === "cobro") {
            let estado = 1;
            if (opciones.cobroResponse === 2) {
              estado = 2;
            }
            const motivosArray = [
              "Pago completo",
              "Pago incompleto",
              "El médico pagó la cuenta",
            ];
            updateHistorial(
              actividadSeleccionada,
              estado,
              comentarios || "",
              motivosArray[(opciones.cobroResponse || 1) - 1]
            );
          } else {
            updateHistorial(
              actividadSeleccionada,
              selectState || 1,
              comentarios || "",
              descripcion
            );
          }
          setRefreshActividadesSwitch((prev) => prev + 1);
        }}
      >
        {actividadSeleccionada === "probableAlta" && "Iniciar Pre-Alta"}
        {actividadSeleccionada === "altaMedica" && "Iniciar alta"}
        {actividadSeleccionada !== "altaMedica" &&
          actividadSeleccionada !== "probableAlta" &&
          `Completar ${TRACKER[actividadSeleccionada].title}`}
      </Button>
    </Paper>
  );

  if (actividadSeleccionada !== "habitacionAseada") {
    let permissionsArray = [];
    rol.forEach((role) => {
      if (
        actividadSeleccionada === "detallesCobro" &&
        role === "Atención Integral" &&
        datosPaciente?.aseguradora
      )
        return <></>;
      if (
        actividadSeleccionada === "detallesCobro" &&
        role === "Seguros" &&
        !datosPaciente?.aseguradora
      )
        return <></>;
      if (roles[role] !== undefined) {
        permissionsArray = [...permissionsArray, ...roles[role]];
      }
    });

    if (
      permissionsArray?.includes(actividadSeleccionada) ||
      rol.includes("Administrador")
    ) {
      return componenteActividad;
    }
  }
};

export default AccionesTracker;

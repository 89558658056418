import { Chip, Divider, Grid, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { BiTimer } from "react-icons/bi";
import { MdDateRange } from "react-icons/md";
import CAMAS from "../../constants/camas.json";
import millisToMinutesAndSecondsShort from "../../services/milisToMinutesAndSecondsShort";

const FichaHabitacion = ({ data, onClick }) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <ListItem
        sx={{
          "&:hover": {
            backgroundColor: (theme) => theme.palette.primary.main + "80",
          },
          borderRadius: "10px",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <Grid container gap={1} direction={"row"}>
          {/* clickable box */}
          <Grid
            item
            container
            xs={12}
            md={3}
            sx={{
              width: "100%",
              display: "flex",
            }}
          >
            <Grid item md={12} xs={12}>
              <Typography
                component="p"
                variant="p"
                color="text.primary"
                textAlign={"center"}
              >
                {`${data?.cama}`}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography
                component="p"
                variant="body2"
                color="text.primary"
                textAlign={"center"}
              >
                {CAMAS[data?.cama]
                  ? `${CAMAS[data?.cama]?.Areas} ${
                      CAMAS[data?.cama]["piso/area"]
                    }`
                  : "Sin registrar"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            md={8}
            xs={12}
            gap={1}
            justifyContent="center"
            alignItems="center"
          >
            <Chip
              icon={<MdDateRange />}
              label={`Fecha inicio: ${new Date(
                data?.fechaInicio
              ).toLocaleDateString()}`}
            />
            <Chip
              icon={<MdDateRange />}
              label={`Fecha fin: ${new Date(
                data?.fechaFin
              ).toLocaleDateString()}`}
            />
            <Chip
              icon={<BiTimer />}
              label={`Tiempo de aseo: ${millisToMinutesAndSecondsShort(
                data?.tiempo
              )}`}
            />
          </Grid>
        </Grid>
      </ListItem>

      <Divider />
    </Box>
  );
};

export default FichaHabitacion;

import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FiX } from "react-icons/fi";
const ResetPasswordError = () => {
  return (
    <Grid
      container
      component="main"
      className="bgLogin"
      style={{
        background:
          "url(/assets/img/login.png) center center rgba(90, 42, 130, 0.8)",
        backgroundSize: "cover",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Grid item xs={false} sm={4} md={7} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        container
        alignContent={"center"}
        justifyContent={"center"}
        sx={{
          backgroundColor: "rgba(90, 42, 130, 0.7)",
        }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "white",
          }}
        >
          <img
            style={{ margin: "auto auto 50px auto", width: "200px" }}
            src="/assets/img/logo-blanco.png"
            alt=""
          />
          <Avatar sx={{ m: 1, bgcolor: "red" }}>
            <FiX />
          </Avatar>
          <Typography gutterBottom component="h1" variant="h4">
            Error
          </Typography>
          <Typography
            variant="h6"
            color="textSecondary"
            sx={{ textAlign: "center", color: "white" }}
            component="p"
          >
            {" "}
            Ya has reestablecido tu contraseña o el enlace ha expirado.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordError;
